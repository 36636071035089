/**
 * @author DCHIRUM1
 */
import {Component, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent, MenuItem, Message, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {RfiRequestService} from '../services/rfi/rfi-request.service';
import {ExportPDFService} from '../services/exportPDF/export-pdf.service';
import moment from 'moment';
import {ExcelService} from '../services/excel.service';
import {RfiCostRequestService} from '../services/rfi/rfi-cost-request.service';
import {PartialRfiRequestService} from '../services/rfi/partial-rfi-request.service';
import {Table} from 'primeng/table';

@Component({
	selector: 'app-rfi-request',
	templateUrl: './rfi-request.component.html',
	styleUrls: ['./rfi-request.component.css'],
	providers: [MessageService]
})
export class RfiRequestComponent implements OnInit {
	displayMessage: Message[] = [];
	columns = [];
	selectedCreatedDate = null;
	rfiHeaderFilter = {};
	selectedCMFDCR = null;
	rfiRequests: any [] = [];
	rfiRequestsOrg: any [] = [];
	rfiRequestsOrgAll: any [] = [];
	selectedRFI = null;
	selectedPSA = null;
	selectedPartNum = null;
	selectedChangeRequest = null;
	rangeDates: Date[];
	selectedRFIStatus = null;
	selectedStatus = null;
	selectedProgram = null;
	selectedSupplier = null;
	selectedSupplierCode = null;
	selectedCommodity = null;
	selectedBuyerCDSID = null;
	selectedCopyFrom = null;
	selectedDrCDSID = null;
	/*#### Code Related to Supplier own RFI organization Functionlaity ###*/
	selectedOrganizationDomain = null;
	selectedCostEstimator = null;
	selectedSecondaryEngineer = null;
	selectedStat = null;
	programList = [];
	commodityList = [];
	partNumberList = [];
	supplierCodeList = [];
	buyerCDSIDCodeList = [];
	statusList = [];
	cmfrDCRList = [];
	cmfrDCRMap;
	cmfrDCR;
	buyerSupplier: any;
	filterName: any;
	filterBy: any;
	statusName: any = 'Unread RFIs';
	filterOptions: {};
	progSelectDisabled = false;
	dateRangeSelectDisabled = false;
	detailDialog = false;
	dialogDetails = null;
	detailsRfiId = null;
	status;
	back = 'false';
	searchRfi = 'false';
	onFilterChangeCall = 'false';
	copyRFIDataDailog = false;
	copyFromFlag;
	fromRFI;
	copyFrom = ['Copy From'];
	copyTo = ['Copy To'];
	rfis = [];
	copyRFIProc = false;
	copyToRFIStatus = [1, 2, 5, 6];
	copyFromRFIStatus = [2, 3, 4, 15];
	copyOper = '';
	totalRecords: number;
	loading = true;
	/*#### Code Related to Supplier own RFI organization Functionlaity ###*/
	domainList = [];
	BUYER_IN_PROGRESS_STATUS = ['AWAITING SUPPLIER RESPONSE', 'SUBMITTED TO PD', 'RESUBMITTED',
		'AWAITING UPDATED SUPPLIER RESPONSE', 'OVER THRESHOLD'];
	BUYER_COMPLETED_STATUS = ['ACCEPTED', 'REJECTED', 'CANCELLED', 'EDCM_REJECTED', 'DUPLICATE'];
	BUYER_UNREAD_STATUS = ['UNREAD', 'AWAITING UPDATED SUPPLIER RESPONSE'];
	@ViewChild('dt', {static: true}) private dataTable: Table;
	private program;
	private breadCrumItems: MenuItem[];
	private reSet = false;
	private selectedType  = 'RFQ';


	constructor(private activatedRoute: ActivatedRoute, private router: Router, private rfiService: RfiRequestService,
				private messageService: MessageService, private exportPDFService: ExportPDFService, private excelService: ExcelService,
				private rfiCostRequestService: RfiCostRequestService, private partialRfiRequestService: PartialRfiRequestService) {
	}

	showDetails(details, rfiId) {
		this.detailDialog = true;
		this.dialogDetails = details;
		this.detailsRfiId = rfiId;
	}

	ngOnInit() {
		this.retriveRouteParameter(false,this.selectedType);

		this.columns = [
			{field: 'rfiId', header: 'RFI/RFQ #'},
			{field: 'psa', header: 'PSA #'},
			{field: 'partNumber', header: 'Part Number/Material Number'},
			{field: 'cmfDCR', header: 'CMF/DCR #'},
			{field: 'cmfDCRTitle', header: 'CMF/DCR Title'},
			{field: 'rfiModifiedDate', header: 'Updated Date'},
			/*{field: 'rfiCreatedDate', header: 'Created Date'},*/
			{field: 'status', header: 'RFI/RFQ Status'},
			{field: 'copyFrom', header: 'Copied From'},
			{field: 'leadPrg', header: 'Lead Program'},
			{field: 'supplierCode', header: 'Supplier GSDB Code'},
			//{field: 'changeDesc', header: 'Detailed Description'},
			{field: 'purCommCode', header: 'Material Group Description'},
			{field: 'buyerCode', header: 'Buyer'},
			{field: 'drCDSID', header: 'D&R CDSID'},
			{field: 'supplier', header: 'Supplier'},
			{field: 'secondSupplier', header: 'Secondary Supplier Contact'}
			// { field: 'rfiCreatedDate', header: 'ModifiedDate' },
			// { field: 'materialCost', header: 'Material Cost' },
			// { field: 'totalCost', header: 'Gross Price' },
			// { field: 'deltaCost', header: 'Delta(From Previous Gross)' },
			// { field: 'active', header: 'Status' }
		];

		if (!this.isSupplierRole()) {
			this.columns.push({field: 'secondaryEngineer', header: 'Second D&R / ESA'});
		}

		if (!this.isSupplierRole()) {
			this.columns.push({field: 'costEstimator', header: 'Cost Estimator'});
		}

	}

	refresh(table: any) {
		this.rfiRequests = [];
		this.columns.forEach((key: any) => {
			key['filter'] = '';
		});
		this.dataTable.filters = {};
		this.dataTable.reset();
		//this.clearSearch();
		this.retriveRouteParameter(true, this.selectedType);
	}

	isEmpty(str) {
		return (!str || 0 === str.length);
	}

	goToBuyer() {
		this.router.navigate(['buyer-supplier/' + this.buyerSupplier]);
	}

	onEdit(rowData) {
		console.log('OnEdit');
		console.log('RfiId' + rowData.rfiId);
		const program = this.filterName === 'program' ? this.filterBy : null;
		this.router.navigate(['create-rfi-request/' + this.buyerSupplier + '/edit/' +
		rowData.rfiId + '/' + this.status + '/' + program + '/' + this.searchRfi + '/' + this.back]);
	}

	onSelectRadioButton(rowData: any) {
		// alert(JSON.stringify(rowData.cmfDCR));
		this.cmfrDCR = {label: rowData.cmfDCR.trim(), value: rowData.cmfDCR.trim()};
		// alert(JSON.stringify(this.cmfrDCR));
	}

	onFilterChange(onLoad, onFilterChangeCall?) {
		if (!this.selectedProgram && !this.selectedCommodity && !this.selectedBuyerCDSID
			&& !this.selectedRFI && !this.selectedChangeRequest && !this.selectedPSA && !this.selectedSupplier
			&& !this.selectedRFIStatus && !this.selectedDrCDSID && !this.selectedCostEstimator && !this.selectedSecondaryEngineer && !this.selectedCMFDCR
			&& !this.selectedSupplierCode && !this.selectedPartNum && (!this.rangeDates || this.rangeDates.length === 0)) {
			sessionStorage.removeItem('searchRfi');
			//onLoad = true;
		}

		if (!onLoad) {
			sessionStorage.setItem('searchRfi', 'true');
		}

		this.filterOptions = {
			commodity: this.selectedCommodity,
			program: this.selectedProgram,
			rfi: this.selectedRFI,
			rfiStatus: this.selectedRFIStatus,
			rfiCreatedDate: this.selectedCreatedDate,
			psa: this.selectedPSA,
			supplier: this.selectedSupplier,
			cmfDCRTitle: this.selectedChangeRequest,
			partNumber: this.selectedPartNum,
			supplierCode: this.selectedSupplierCode,
			buyerCDSID: this.selectedBuyerCDSID,
			drCDSID: this.selectedDrCDSID,
			cmfDCR: this.selectedCMFDCR,
			costEstimator: this.selectedCostEstimator,
			secondaryEngineer: this.selectedSecondaryEngineer,
			status: this.selectedStatus,
			supplierOrBuyer: this.isSupplier(),
			copyOper: this.copyOper
		};
		this.retriveRfiHeaders(this.filterName, this.filterBy, this.status, this.filterOptions);
	}

	onFilter(event, dt) {
		const filteredValues = event.filteredValue;
		console.log(filteredValues);
	}

	containsString(mainStr, partStr) {
		let isContains = false;
		mainStr += '';
		if (mainStr && partStr) {
			isContains = mainStr.trim().toLowerCase().includes(partStr.trim().toLowerCase());
		}

		return isContains;
	}

	/*#### line 241 to 243 Code Related to Supplier own RFI organization Functionlaity ###*/
	retriveRfiRequests(rfis) {
		const uniqueDomainsSet = new Set<string>();

		const filterRfis = rfis.filter(record => {
			let isFilter = true;
			if (this.selectedCommodity) {
				isFilter = isFilter && (this.containsString(record.purCommCode, this.selectedCommodity)
					|| this.selectedCommodity === 'All Commodities');
				sessionStorage.setItem('commodity', this.selectedCommodity);
			} else {
				sessionStorage.setItem('commodity', null);
			}

			if (this.selectedProgram && this.selectedProgram !== 'null') {
				isFilter = isFilter && (this.containsString(record.leadPrg, this.selectedProgram.trim())
					|| this.selectedProgram === 'All Programs');
				sessionStorage.setItem('program', this.selectedProgram);
			} else {
				sessionStorage.setItem('program', null);
			}

			if (this.selectedRFI && this.selectedRFI !== 'null') {
				isFilter = isFilter && (this.containsString(record.rfiId, this.selectedRFI.trim()));
				sessionStorage.setItem('rfi', this.selectedRFI);
			} else {
				sessionStorage.setItem('rfi', null);
			}
			if (this.selectedCreatedDate && this.selectedCreatedDate !== 'null') {
				isFilter = isFilter && (this.containsString(record.rfiCreatedDate, this.selectedCreatedDate.trim()));
				sessionStorage.setItem('rfiCreatedDate', this.selectedCreatedDate);
			} else {
				sessionStorage.setItem('rfiCreatedDate', null);
			}
			if (this.selectedRFIStatus && this.selectedRFIStatus !== 'null') {

				const supp = this.isSupplier();

				if (supp) {
					isFilter = isFilter && (this.containsString(record.supplierStatus, this.selectedRFIStatus.trim()));
				} else {
					isFilter = isFilter && (this.containsString(record.buyerStatus, this.selectedRFIStatus.trim()));
					/*if (this.selectedRFIStatus.trim() == 'I') {
						isFilter = isFilter && (this.BUYER_IN_PROGRESS_STATUS.includes(record.buyerStatus.trim()));
					} else if (this.selectedRFIStatus.trim() == 'C') {
						isFilter = isFilter && (this.BUYER_COMPLETED_STATUS.includes(record.buyerStatus.trim()));
					} else {
						isFilter = isFilter && (this.BUYER_UNREAD_STATUS.includes(record.buyerStatus.trim()));
					}*/

				}

				sessionStorage.setItem('rfiStatus', this.selectedRFIStatus);
			} else {
				sessionStorage.setItem('rfiStatus', null);
			}

			if (this.selectedPSA && this.selectedPSA !== 'null') {
				isFilter = isFilter && (this.containsString(record.psa, this.selectedPSA.trim()));
				sessionStorage.setItem('psa', this.selectedPSA);
			} else {
				sessionStorage.setItem('psa', null);
			}

			if (this.selectedSupplier && this.selectedSupplier !== 'null') {
				isFilter = isFilter && (this.containsString(record.supplier, this.selectedSupplier.trim()));
				sessionStorage.setItem('supplier', this.selectedSupplier);
			} else {
				sessionStorage.setItem('supplier', null);
			}

			if (this.selectedChangeRequest && this.selectedChangeRequest !== 'null') {
				isFilter = isFilter && (this.containsString(record.cmfDCRTitle, this.selectedChangeRequest.trim()));
				sessionStorage.setItem('cmfDCRTitle', this.selectedChangeRequest);
			} else {
				sessionStorage.setItem('cmfDCRTitle', null);
			}

			if (this.selectedPartNum) {
				const desc = this.selectedPartNum; //this.getPartNumber(this.selectedPartNum);
				isFilter = isFilter && (this.containsString(record.partNumber, desc)
					|| desc === 'All Part Numbers');
				sessionStorage.setItem('partNumber', desc);
			} else {
				sessionStorage.setItem('partNumber', null);
			}

			if (this.selectedSupplierCode) {
				isFilter = isFilter && (this.containsString(record.siteCode, this.selectedSupplierCode)
					|| this.selectedSupplierCode === 'All Supliers');
				sessionStorage.setItem('siteCode', this.selectedSupplierCode);
			} else {
				sessionStorage.setItem('siteCode', null);
			}

			if (this.selectedBuyerCDSID) {
				isFilter = isFilter && (this.containsString(record.buyerCode, this.selectedBuyerCDSID)
					|| this.selectedBuyerCDSID === 'All Buyers');
				sessionStorage.setItem('buyer', this.selectedBuyerCDSID);
			} else {
				sessionStorage.setItem('buyer', null);
			}

			if (this.selectedDrCDSID) {
				isFilter = isFilter && (this.containsString(record.drCDSID, this.selectedDrCDSID));
				sessionStorage.setItem('pdEngg', this.selectedDrCDSID);
			} else {
				sessionStorage.setItem('pdEngg', null);
			}
			if (this.selectedCMFDCR) {
				isFilter = isFilter && (this.containsString(record.cmfDCR, this.selectedCMFDCR));
				sessionStorage.setItem('cmfDCR', this.selectedCMFDCR);
			} else {
				sessionStorage.setItem('cmfDCR', null);
			}
			if (this.selectedCostEstimator) {
				isFilter = isFilter && (this.containsString(record.costEstimator, this.selectedCostEstimator));
				sessionStorage.setItem('costEst', this.selectedCostEstimator);
			} else {
				sessionStorage.setItem('costEst', null);
			}
			if (this.selectedSecondaryEngineer) {
				isFilter = isFilter && (this.containsString(record.secondaryEngineer, this.selectedSecondaryEngineer));
				sessionStorage.setItem('secondaryeng', this.selectedSecondaryEngineer);
			} else {
				sessionStorage.setItem('secondaryeng', null);
			}

			if (this.selectedStatus) {
				isFilter = isFilter && record.active === this.selectedStatus;
			}

			if (this.rangeDates && this.rangeDates.length > 0) {
				sessionStorage.setItem('rangeDates', JSON.stringify(this.rangeDates));
				const startDate = new Date(this.rangeDates[0]);
				startDate.setHours(0, 0, 0, 0);

				isFilter = isFilter && new Date(record.rfiCreatedDate).getTime() > startDate.getTime();
				if (this.rangeDates.length === 2 && !this.rangeDates[1]) {
					const endDate = new Date(this.rangeDates[0]);
					endDate.setHours(23, 59, 59, 999);
					isFilter = isFilter && new Date(record.rfiCreatedDate).getTime() < endDate.getTime();
				}

				if (this.rangeDates.length === 2 && this.rangeDates[1]) {
					const endDate = new Date(this.rangeDates[1]);
					endDate.setHours(23, 59, 59, 999);
					isFilter = isFilter && new Date(record.rfiCreatedDate).getTime() < endDate.getTime();
				}
			} else {
				sessionStorage.setItem('rangeDates', null);
			}

			if (this.selectedStat) {
				if (this.isSupplier()) {
					isFilter = isFilter && record.supplierStatus === this.selectedStat.supplierStatus;
				} else {
					isFilter = isFilter && record.buyerStatus === this.selectedStat.buyerStatus;
				}
			}
			/*/!*#### line 395 to 414 Code Related to Supplier own RFI organization Functionlaity ###*!/
			const domain = this.getEmailDomain(record.supplier);

			// Add domain to the set
			uniqueDomainsSet.add(domain);
			if (record.secondSupplier && record.secondSupplier !== '') {
				const secDomain = this.getEmailDomain(record.secondSupplier);
				uniqueDomainsSet.add(secDomain);
			}

			if (!this.isAdminRole() && this.isSupplierRole()) {
				if (!this.selectedOrganizationDomain) {
					isFilter = isFilter
						&& (record.supplier.toLowerCase() === sessionStorage.getItem('emailAddress').toLowerCase()
							|| (record.secondSupplier && record.secondSupplier.toLowerCase() ===
								sessionStorage.getItem('emailAddress').toLowerCase()));
				} else {
					isFilter = isFilter && (this.containsString(record.supplier, this.selectedOrganizationDomain)
						|| (record.secondSupplier && this.containsString(record.secondSupplier, this.selectedOrganizationDomain)));
				}
			}*/

			return isFilter;
		});
		/*#### line 418 to 428 Code Related to Supplier own RFI organization Functionlaity ###*/

		this.domainList = Array.from(uniqueDomainsSet);

		return filterRfis;
	}

	getEmailDomain(email: string): string {
		// Split email into username and domain
		if (email){
			const [, domain] = email.split('@');
			return '@' + domain;
		}
		return '';
	}

	clearSearch() {
		this.progSelectDisabled = false;
		this.searchRfi = 'false';
		this.progSelectDisabled = false;
		sessionStorage.removeItem('searchRfi');
		this.selectedCommodity = null;
		this.selectedProgram = null;
		this.selectedPartNum = null;
		this.selectedSupplierCode = null;
		this.selectedBuyerCDSID = null;
		this.selectedStatus = null;
		this.selectedPSA = null;
		this.selectedRFI = null;
		this.selectedRFIStatus = null;
		this.selectedSupplier = null;
		this.selectedChangeRequest = null;
		this.selectedDrCDSID = null;
		this.selectedCMFDCR = null;
		this.selectedCostEstimator = null;
		this.selectedSecondaryEngineer = null;

		sessionStorage.setItem('program', null);
		sessionStorage.setItem('commodity', null);
		sessionStorage.setItem('costEst', null);
		sessionStorage.setItem('secondaryeng', null);
		sessionStorage.setItem('rfi', null);
		sessionStorage.setItem('rfiStatus', null);
		sessionStorage.setItem('psa', null);
		sessionStorage.setItem('supplier', null);
		sessionStorage.setItem('cmfDCRTitle', null);
		sessionStorage.setItem('cmfDCR', null);
		sessionStorage.setItem('partNumber', null);
		sessionStorage.setItem('siteCode', null);
		sessionStorage.setItem('buyer', null);
		sessionStorage.setItem('pdEngg', null);
		sessionStorage.setItem('rangeDates', null);


		if (!this.dateRangeSelectDisabled) {
			this.rangeDates = null;
		}

		this.filterOptions = {};
		this.filterName = null;
		this.filterBy = null;
		this.status = null;

		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (this.searchRfi === 'true'
			&& (!this.isBuyerRole() && !this.isPdEnggRole()
				&& !this.isCostEstimatorRole() && !apsRoles.includes('EDCM_UPDATE'))) {
			this.rfiRequestsOrg = [];
		}

		this.onFilterChange(!apsRoles.includes('EDCM_UPDATE'));
	}

	onPrint() {
		//this.messageService.add({life: 300000, severity:'info', summary: 'Info', detail:'Pinting functionality is in progress.'});
		const tableContent = [];
		const headerRow = [];
		this.columns.forEach((column) => {
			headerRow.push({text: column.header, bold: true, width: '20px'});
		});

		tableContent.push(headerRow);
		this.rfiRequests.forEach((rfiRequest) => {
			tableContent.push([{text: rfiRequest.rfiId}, {text: rfiRequest.partNumber},
				{text: rfiRequest.leadPrg}, {text: rfiRequest.buyerCode},
				{text: rfiRequest.supplierCod}, {text: rfiRequest.supplierCode},
				{text: rfiRequest.purCommCode}, {text: rfiRequest.cmfDCRTitle}, {text: rfiRequest.changeDesc}
				//{'text':rfiRequest.rfiCreatedDate}, {'text':rfiRequest.materialCost},
				// {'text':rfiRequest.totalCost}, {'text':rfiRequest.deltaCost},
				//{'text':rfiRequest.active}
			]);
		});

		this.exportPDFService.exportPDF(tableContent, 'RfiRequests.pdf');
	}

	exportAsXLSX() {
		const rfiExports = [];
		this.rfiRequests.forEach(rfi => {
			const rfiExport = {};
			rfiExport['RFI #'] = rfi.rfiId;
			rfiExport['Part #'] = rfi.partNumber;
			rfiExport['Lead Program'] = rfi.leadPrg;
			rfiExport['Buyer'] = rfi.buyerCode;
			rfiExport['Suppiler'] = rfi.supplier;
			rfiExport['Site Code'] = rfi.supplierCode;
			rfiExport['Material Group'] = rfi.purCommCode && rfi.purCommCode.includes('COMMODITY CODE ERROR') ? '' : rfi.purCommCode;
			rfiExport['Change Request Title'] = rfi.cmfDCRTitle;
			rfiExport['Detailed Description of Change'] = rfi.changeDesc;
			rfiExport['RFI Status'] = rfi.status;
			rfiExport['Created Date'] = rfi.rfiCreatedDate ? moment(rfi.rfiCreatedDate).format('MM/DD/YYYY') : rfi.rfiCreatedDate;

			rfiExports.push(rfiExport);
		});

		const fileName = this.isSupplier() ? 'SupplierData' : 'BuyerData';
		this.excelService.exportAsExcelFile(rfiExports, fileName);
	}

	exportAllAsXLSX() {
		const that = this;
		const supp = this.isSupplier();
		let userId = supp ? sessionStorage.getItem('emailAddress') : sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles && this.isAdminRole()) {
			userId = 'eDCM_IT_ADMIN';
		}

		if (apsRoles && apsRoles.includes('EDCM_VIEW_ONLY')) {
			userId = 'EDCM_VIEW_ONLY';
		}

		that.rfiService.findAllRfiData(supp, userId).subscribe(response => {
			const rfiExports = [];

			response.forEach(rfi => {
				const rfiExport = {};
				rfiExport['RFI #'] = rfi.rfiNo;
				rfiExport['RFI Created Date'] = rfi.creationDate ? moment(rfi.creationDate).format('MM/DD/YYYY') : rfi.creationDate;
				rfiExport['RFI Status'] = rfi.curStatus;
				rfiExport['PSA #'] = rfi.psa;
				rfiExport['CMF / DCR #'] = rfi.cmfDCR;
				rfiExport['CMF / DCR Title'] = rfi.cmfDCRTitle;
				rfiExport['CMF / DCR Change Description'] = rfi.cmfDCRDesc;
				rfiExport['D&R CDSID'] = rfi.drEngr;
				rfiExport['PART NUMBER/MATERIAL NUMBER'] = rfi.pprefix.trim() + '-' + rfi.pbase.trim() + '-' + rfi.psuffix.trim();


				if (!supp) {
					rfiExport['Initial Piece Price Target'] = rfi.mcosTgt;
					rfiExport['Initial Piece Price Target Currency'] = rfi.mtCur;
					rfiExport['Initial Production Tooling Target'] = rfi.tcosTgt;
					rfiExport['Initial Production Tooling Target Currency'] = rfi.ttCur;
					rfiExport['Piece Price Status'] = rfi.engemSts;
					rfiExport['Piece Price Status Currency'] = rfi.engemCur;
					rfiExport['Production Tooling Status'] = rfi.tcosSts;
					rfiExport['Production Tooling Status Currency'] = rfi.engetCur;
					rfiExport['Piece Price Estimate'] = rfi.mcosCts;
					rfiExport['Piece Price Estimate Currency'] = rfi.mstCur;
					rfiExport['Production Tooling Estimate'] = rfi.engetSts;
					rfiExport['Production Tooling Estimate Currency'] = rfi.tstCur;
				}

				rfiExport['Lead Program'] = rfi.program;
				rfiExport['MP&L Plant Code'] = rfi.plant;
				rfiExport['Part Name'] = rfi.partName;
				rfiExport['Material Group/Commodity Code'] = rfi.commCD;
				//rfiExport['PURCHASE COMM'] = rfi.purComm;
				rfiExport['Buyer CDSID'] = rfi.buyer;
				rfiExport['Supplier Email'] = rfi.supplierEmail;
				rfiExport['PGROUP'] = rfi.purGroup;
				rfiExport['Supplier GSDB Code'] = rfi.supplier;
				//rfiExport['SUPPLIER CONTACT'] = rfi.scontact;
				rfiExport['Supplier Comments'] = rfi.scomment;
				rfiExport['Buyer Comments'] = rfi.buyerComment;
				rfiExport['PD Comments'] = rfi.pdComment;
				rfiExport['Lump Sum ED&T'] = rfi.lumpSum;
				rfiExport['Lump Sum ED&T Value Includes Financing'] = rfi.lumpSumFIN;
				rfiExport['Row TYPE'] = rfi.rowType;
				rfiExport['Cost Description'] = rfi.costDesc;
				rfiExport['Amount '] = rfi.amountA;
				rfiExport['ResponseCurrency '] = rfi.responsecur;
				//rfiExport['PD TOOLING'] = rfi.pdTooling;


				if (this.isRecordFilter(rfi)) {
					rfiExports.push(rfiExport);
				}
			});

			that.excelService.exportAsExcelFile(rfiExports, 'RFI_Data');
		});
	}


	onCMFDCRSelect() {
		this.rfiRequests = this.retriveRfiRequests(this.rfiRequestsOrg);
		this.rfiRequests = this.rfiRequests.filter(record => record.cmfDCR.trim() === this.cmfrDCR.label);
	}

	public copyRFIMenu(state) {
		this.copyRFIDataDailog = state;
		if (!state) {
			this.clearCopyRFI();
		}
	}

	public showFromToCopySelection() {
		return (this.copyFromFlag === 'true' || this.copyFromFlag === 'false');
	}

	public onRowSelect(event) {
		if (this.copyFromFlag === 'true') {
			this.copyFrom = [];
			this.copyFrom.push(event.data.rfiId);
		}

		if (this.copyFromFlag === 'false') {
			this.copyTo = [];
			this.rfis.forEach(data => {
				this.copyTo.push(data.rfiId);
			});
		}
	}

	isAdminRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer') || apsRoles.includes('EDCM_VIEW_ONLY');
	}

	isPdEnggRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('PD_Engineer') || apsRoles.includes('JV Engineer');
	}

	isCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('CostEstimator') || apsRoles.includes('JV Costestimator');
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	isJVRoles() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('JV Engineer') || apsRoles.includes('JV Buyer')
			|| apsRoles.includes('JV Costestimator') || apsRoles.includes('PD_Engineer');
	}

	isSupplierRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('EDCM_UPDATE');
	}

	clearCopyRFI() {
		this.copyFromFlag = null;
		this.copyFrom = ['Copy From'];
		this.copyTo = ['Copy To'];
		this.rfis = [];
		this.copyRFIProc = false;
		const filterCha = sessionStorage.getItem('searchRfi') === 'true' ? false : true;
		this.cmfrDCR = null;
		this.copyOper = '';
		this.updateRFIDataList(filterCha);
	}

	clearRfiSelection(toRfi) {
		this.rfis = [];
		if (toRfi) {
			this.columns.forEach((key: any) => {
				key['filter'] = '';
			});
		}
		const filterCha = sessionStorage.getItem('searchRfi') === 'true' ? false : true;
		this.copyOper = toRfi ? 'CopyTo' : 'CopyFrom';
		this.onFilterChange(filterCha);

		this.rfiRequests = this.rfiRequests.filter(record => {
			let isFilter = true;
			if (toRfi) {
				if (this.isSupplier()) {
					const fromRFI = this.copyFrom[0];
					const rfiId = record.rfiId;
					isFilter = isFilter
						&& this.copyToRFIStatus.includes(record.statusId)
						&& fromRFI !== rfiId;
				}
			} else {
				if (this.isSupplier()) {
					isFilter = isFilter
						&& this.copyFromRFIStatus.includes(record.statusId);
				}
			}

			return isFilter;
		});

		if (this.cmfrDCR) {
			this.rfiRequests = this.rfiRequests.filter(record => record.cmfDCR.trim() === this.cmfrDCR.label);
		}
	}

	public isFromToRFIAvailable() {
		return this.copyFrom[0] !== 'Copy From' && this.copyTo[0] !== 'Copy To';
	}

	public copRFIs() {
		const fromRFI = this.copyFrom[0];
		const toRFIS = this.copyTo;

		this.rfiCostRequestService.copyRFICosts(fromRFI, toRFIS).subscribe(response => {
			this.messageService.add({
				severity: 'success',
				summary: 'Success',
				detail: 'All RFI costs has been copied successfully to target RFIs'
			});
			this.refresh(null);
		});
	}

	private isSupplier() {
		return this.buyerSupplier.toLowerCase() === 'supplier';
	}

	private isRecordFilter(record): boolean {
		let isFilter = true;

		if (this.selectedRFI) {
			isFilter = isFilter && this.containsString(record.rfiNo, this.selectedRFI);
		}

		if (this.selectedPSA) {
			isFilter = isFilter && this.containsString(record.psa, this.selectedPSA);
		}

		if (this.selectedPartNum) {
			isFilter = isFilter && (this.containsString((record.pprefix.trim() + '-' + record.pbase.trim() + '-'
				+ record.psuffix.trim()), this.selectedPartNum));
		}

		if (this.selectedChangeRequest) {
			isFilter = isFilter && this.containsString(record.cmfDCRTitle, this.selectedChangeRequest);
		}

		// if (this.rangeDates && this.rangeDates.length > 0) {
		// 	isFilter = isFilter && new Date(record.creationDate).getTime() > this.rangeDates[0].getTime();
		// 	if (this.rangeDates.length === 2 && this.rangeDates[1]) {
		// 		const endDate = this.rangeDates[1];
		// 		isFilter = isFilter && new Date(record.creationDate).getTime() < endDate.getTime();
		// 	}
		// }

		if (this.rangeDates && this.rangeDates.length > 0) {
			const startDate = this.rangeDates[0];
			startDate.setHours(0, 0, 0, 0);

			isFilter = isFilter && new Date(record.creationDate).getTime() > startDate.getTime();
			if (this.rangeDates.length === 2 && !this.rangeDates[1]) {
				const endDate = new Date(this.rangeDates[0]);
				endDate.setHours(23, 59, 59, 999);
				isFilter = isFilter && new Date(record.creationDate).getTime() < endDate.getTime();
			}

			if (this.rangeDates.length === 2 && this.rangeDates[1]) {
				const endDate = new Date(this.rangeDates[1]);
				endDate.setHours(23, 59, 59, 999);
				isFilter = isFilter && new Date(record.creationDate).getTime() < endDate.getTime();
			}
		}

		if (this.selectedProgram) {
			isFilter = isFilter && this.containsString(record.program, this.selectedProgram);
		}

		if (this.selectedSupplier) {
			isFilter = isFilter && this.containsString(record.supplierEmail, this.selectedSupplier);
		}

		if (this.selectedSupplierCode) {
			isFilter = isFilter && this.containsString(record.supplier, this.selectedSupplierCode);
		}

		if (this.selectedCommodity) {
			isFilter = isFilter && this.containsString(record.purComm, this.selectedCommodity);
		}

		if (this.selectedBuyerCDSID) {
			isFilter = isFilter && this.containsString(record.buyer, this.selectedBuyerCDSID);
		}

		if (this.selectedDrCDSID) {
			isFilter = isFilter && this.containsString(record.drCDSID, this.selectedDrCDSID);
		}

		if (this.selectedCMFDCR) {
			isFilter = isFilter && this.containsString(record.cmfDCR, this.selectedCMFDCR);
		}

		if (this.selectedCostEstimator) {
			isFilter = isFilter && this.containsString(record.costEstimator, this.selectedCostEstimator);
		}

		if (this.selectedSecondaryEngineer) {
			isFilter = isFilter && this.containsString(record.secondaryEngineer, this.selectedSecondaryEngineer);
		}

		if (this.selectedStat) {
			isFilter = isFilter && (record.curStatus === this.selectedStat.supplierStatus
				|| record.curStatus === this.selectedStat.buyerStatus);
		}

		if (this.status && this.status !== 'SupplierSelectRFI' && this.status !== 'BuyerSelectRFI' && this.status !== 'null') {
			isFilter = isFilter && record.rfiStatus === this.status;
		}

		if (this.status === 'null') {
			if (this.isSupplier()) {
				return (record.curStatus.toUpperCase() === 'Accepted'.toUpperCase()
					|| record.curStatus.toUpperCase() === 'Rejected'.toUpperCase()
					|| record.curStatus.toUpperCase() === 'Cancelled'.toUpperCase()
					|| record.curStatus.toUpperCase() === 'Duplicate'.toUpperCase());
			}
		}

		return isFilter;
	}

	private getPartNumber(partNum) {
		const partNumer = partNum.partIdentity
			? partNum.partIdentity.partPrefix.trim() + '-' + partNum.partIdentity.partBase.trim() + '-' +
			partNum.partIdentity.partSuffix.trim()
			: 'All Part Numbers';

		return partNumer;
	}

	private retriveRfiHeaders(filterName, filterBy, status, filterOptions, event?: LazyLoadEvent) {
		/*#### 777 to 780 Code Related to Supplier own RFI organization Functionlaity ###*/

		const domainChange = this.selectedOrganizationDomain != null && this.selectedOrganizationDomain !== '';
		const supp = this.isSupplier() && !domainChange;
		let userId = supp ? sessionStorage.getItem('emailAddress') : sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles && this.isAdminRole()) {
			userId = 'eDCM_IT_ADMIN';
		}

		if (apsRoles && apsRoles.includes('EDCM_VIEW_ONLY')) {
			userId = 'EDCM_VIEW_ONLY';
		}

		let role = '';

		if (this.isBuyerRole()) {
			role = 'Buyer';
		}

		if (apsRoles.includes('EDCM_UPDATE')) {
			role = 'EDCM_UPDATE';
		}

		if (this.isPdEnggRole()) {
			role = 'PD_Engineer';
		}

		if (this.isCostEstimatorRole()) {
			role = 'CostEstimator';
		}

		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer') || apsRoles.includes('EDCM_VIEW_ONLY')) {
			role = 'eDCM_IT_ADMIN';
		}

		this.loading = true;
		let page = 0;
		let size = 10;
		let sortColumn = '';
		let sortOrder = 0;
		const radioValue = this.selectedType;

		if (event) {
			page = event.first / event.rows;
			size = event.rows;
			sortColumn = event.sortField;
			sortOrder = event.sortOrder;

		}

		if (status && status !== 'null' && !(status === 'BuyerSelectRFI' || status === 'SupplierSelectRFI')) {
			this.selectedRFIStatus = status;
		}

		this.prePopulateFilter(filterName, filterBy, event);
		filterOptions = this.filterOptions;

		this.rfiService.findAll(supp, userId, role, page, size, sortColumn, sortOrder,radioValue, filterOptions).subscribe(result => {
			this.loading = false;
			this.rfiRequestsOrg = result['user'].map(rfi => {
				rfi.purCommCode = supp && rfi.purCommCode && rfi.purCommCode.includes('COMMODITY CODE ERROR') ? '' : rfi.purCommCode;
				return rfi;
			});

			let dataFetchFor = 'all';

			if (supp) {
				dataFetchFor = 'user';
			}

			this.totalRecords = result.totalRecordsUser;
			if (sessionStorage.getItem('searchRfi') === 'true') {
				/*#### commented out 850 to 852 Code Related to Supplier own RFI organization Functionlaity ###*/
				/*if (this.isSupplier() || this.isBuyerRole() || this.isPdEnggRole() || this.isCostEstimatorRole()) {
					this.totalRecords = result.totalRecordsUser;
				}else{*/
				this.totalRecords = result.totalRecordsAll;
			}

			if (this.searchRfi === 'true'
				&& (!this.isBuyerRole())) {
				this.rfiRequestsOrg = [];
			}

			this.rfiRequestsOrgAll = result[dataFetchFor].map(rfi => {
				rfi.purCommCode = supp && rfi.purCommCode && rfi.purCommCode.includes('COMMODITY CODE ERROR') ? '' : rfi.purCommCode;
				return rfi;
			});

			// if (status && status !== 'null' && filterName === 'program' || filterName === 'dateRange') {
			// 	this.rfiRequestsOrg = this.rfiRequestsOrg.filter(res => res.isActive === status);
			// 	this.rfiRequestsOrgAll = this.rfiRequestsOrgAll.filter(res => res.isActive === status);
			// }

			if (status === 'null' && filterName === 'program') {
				this.rfiRequestsOrg = this.rfiRequestsOrg.filter(res => (res.isActive === 'Accepted' ||
					res.isActive === 'Rejected' || res.isActive === 'Cancelled' || res.isActive === 'Duplicate'));

				this.rfiRequestsOrgAll = this.rfiRequestsOrgAll.filter(res => (res.isActive === 'Accepted'
					|| res.isActive === 'Rejected' || res.isActive === 'Cancelled' || res.isActive === 'Duplicate'));
			}

			if (this.isSupplier()) {
				this.rfiRequestsOrg.forEach(res => {
					res['status'] = res.supplierStatus;
				});

				this.rfiRequestsOrgAll.forEach(res => {
					res['status'] = res.supplierStatus;
				});
			} else {
				this.rfiRequestsOrg.forEach(res => {
					res['status'] = res.buyerStatus;
				});

				this.rfiRequestsOrgAll.forEach(res => {
					res['status'] = res.buyerStatus;
				});
			}

			const searchRfi = sessionStorage.getItem('searchRfi') !== null ? sessionStorage.getItem('searchRfi') : this.back;

			if (searchRfi === 'true'
				&& (!this.isBuyerRole() && !this.isPdEnggRole() && !this.isSupplierRole()
					&& !this.isCostEstimatorRole() && !this.isAdminRole())) {
				this.rfiRequestsOrg = [];
			}

			const filterCha = sessionStorage.getItem('searchRfi') === 'true' ? false : true;
			this.updateRFIDataList(filterCha);
			this.updateStatusString();
		});
	}

	private prePopulateFilter(filterName, filterBy, event?) {
		if (filterName === 'program' || filterName === 'dateRange') {
			if (filterName === 'program') {
				//this.selectedProgram = filterBy;//this.programList.filter(d => d.name === filterBy)[0];
				this.selectedProgram = filterBy && filterBy !== 'null' ? filterBy : null;
				if (filterBy && filterBy !== 'null') {
					this.progSelectDisabled = true;
				}
			}

			if (filterName === 'dateRange') {
				this.rangeDates = [];
				let strArr = [];
				if (filterBy.includes('-')) {
					strArr = filterBy.split('-');
				} else {
					strArr = filterBy.split('>');
					strArr[0] = 15;
					strArr[1] = 1000;
				}

				const enddate = moment().hours(0).minutes(0).seconds(0);
				enddate.subtract(strArr[0], 'days');

				if (!this.isEmpty(strArr[1])) {
					const startdate = moment().hours(0).minutes(0).seconds(0);
					startdate.subtract(strArr[1], 'days');
					this.rangeDates.push(startdate.toDate());
				}

				this.rangeDates.push(enddate.toDate());

				console.log('startdate : ' + this.rangeDates[0] + ' : enddate : ' + this.rangeDates[1]);
				this.dateRangeSelectDisabled = true;
			}
		}


		let startDate = '';
		let endDate = '';
		if (this.rangeDates) {

			if (this.rangeDates.length > 0 && this.rangeDates[0]) {
				const date = new Date(this.rangeDates[0]);
				const month = date.getMonth() + 1;
				startDate = `${date.getDate()}-${month > 9 ? month : '0' + month}-${date.getFullYear()}`;
			}
			if (this.rangeDates.length > 1 && this.rangeDates[1]) {
				const date = new Date(this.rangeDates[1]);
				const month = date.getMonth() + 1;
				endDate = `${date.getDate()}-${month > 9 ? month : '0' + month}-${date.getFullYear()}`;
			}
		}


		// const program = sessionStorage.getItem('program');
		// if (!this.selectedProgram && program && 'null' !== program) {
		// 	this.selectedProgram = program;
		// }

		// const commodity = sessionStorage.getItem('commodity');
		// if (!this.selectedCommodity && commodity && 'null' !== commodity) {
		// 	this.selectedCommodity = commodity;
		// }

		// const buyer = sessionStorage.getItem('buyer');
		// if (!this.selectedBuyerCDSID && buyer && 'null' !== buyer) {
		// 	this.selectedBuyerCDSID = buyer;
		// }

		// const pdEngg = sessionStorage.getItem('pdEngg');
		// if (!this.selectedDrCDSID && pdEngg && 'null' !== pdEngg) {
		// 	this.selectedDrCDSID = pdEngg;
		// }

		// const cmfDCR = sessionStorage.getItem('cmfDCR');
		// if (!this.selectedCMFDCR && cmfDCR && 'null' !== cmfDCR) {
		// 	this.selectedCMFDCR = cmfDCR;
		// }

		// const costEstimator = sessionStorage.getItem('costEst');
		// if (!this.selectedCostEstimator && costEstimator && 'null' !== costEstimator) {
		// 	this.selectedCostEstimator = costEstimator;
		// }

		// const siteCode = sessionStorage.getItem('siteCode');
		// if (!this.selectedSupplierCode && siteCode && 'null' !== siteCode) {
		// 	this.selectedSupplierCode = siteCode;
		// }

		// const partNumber = sessionStorage.getItem('partNumber');
		// if (!this.selectedPartNum && partNumber && 'null' !== partNumber) {
		// 	this.selectedPartNum = partNumber;
		// }

		this.filterOptions = {
			commodity: this.selectedCommodity,
			program: this.selectedProgram,
			rfi: this.selectedRFI,
			rfiStatus: this.selectedRFIStatus,
			rfiCreatedDate: this.selectedCreatedDate,
			psa: this.selectedPSA,
			supplier: this.selectedSupplier,
			cmfDCRTitle: this.selectedChangeRequest,
			partNumber: this.selectedPartNum,
			supplierCode: this.selectedSupplierCode,
			buyerCDSID: this.selectedBuyerCDSID,
			drCDSID: this.selectedDrCDSID,
			cmfDCR: this.selectedCMFDCR,
			costEstimator: this.selectedCostEstimator,
			secondaryEngineer: this.selectedSecondaryEngineer,
			status: this.selectedStatus,
			endDate,
			startDate,
			supplierOrBuyer: this.isSupplier(),
			copyOper: this.copyOper,
			copyFrom: this.selectedCopyFrom
		};

		if (event && event.filters && Object.keys(event.filters).length !== 0) {
			if (event.filters.rfiId && event.filters.rfiId.value) {
				this.filterOptions['rfi'] = event.filters.rfiId ? event.filters.rfiId.value : null;
			}
			if (event.filters.psa && event.filters.psa.value) {
				this.filterOptions['psa'] = event.filters.psa ? event.filters.psa.value : null;
			}
			if (event.filters.partNumber && event.filters.partNumber.value) {
				this.filterOptions['partNumber'] = event.filters.partNumber ? event.filters.partNumber.value : null;
			}
			if (event.filters.cmfDCR && event.filters.cmfDCR.value) {
				this.filterOptions['cmfDCR'] = event.filters.cmfDCR ? event.filters.cmfDCR.value : null;
			}
			if (event.filters.drCDSID && event.filters.drCDSID.value) {
				this.filterOptions['drCDSID'] = event.filters.drCDSID ? event.filters.drCDSID.value : null;
			}
			if (event.filters.cmfDCRTitle && event.filters.cmfDCRTitle.value) {
				this.filterOptions['cmfDCRTitle'] = event.filters.cmfDCRTitle ? event.filters.cmfDCRTitle.value : null;
			}
			if (event.filters.rfiModifiedDate && event.filters.rfiModifiedDate.value) {
				this.filterOptions['rfiModifiedDate'] = event.filters.rfiModifiedDate ? event.filters.rfiModifiedDate.value : null;
			}
			if (event.filters.status && event.filters.status.value) {
				this.filterOptions['rfiStatus'] = event.filters.status ? event.filters.status.value : null;
			}
			if (event.filters.leadPrg && event.filters.leadPrg.value) {
				this.filterOptions['program'] = event.filters.leadPrg ? event.filters.leadPrg.value : null;
			}
			if (event.filters.supplier && event.filters.supplier.value) {
				this.filterOptions['supplier'] = event.filters.supplier ? event.filters.supplier.value : null;
			}
			if (event.filters.supplierCode && event.filters.supplierCode.value) {
				this.filterOptions['supplierCode'] = event.filters.supplierCode ? event.filters.supplierCode.value : null;
			}
			if (event.filters.purCommCode && event.filters.purCommCode.value) {
				this.filterOptions['commodity'] = event.filters.purCommCode ? event.filters.purCommCode.value : null;
			}
			if (event.filters.rfiCreatedDate && event.filters.rfiCreatedDate.value) {
				this.filterOptions['rfiCreatedDate'] = event.filters.rfiCreatedDate ? event.filters.rfiCreatedDate.value : null;
			}
			if (event.filters.costEstimator && event.filters.costEstimator.value) {
				this.filterOptions['costEstimator'] = event.filters.costEstimator ? event.filters.costEstimator.value : null;
			}
			if (event.filters.secondaryEngineer && event.filters.secondaryEngineer.value) {
				this.filterOptions['secondaryEngineer'] = event.filters.secondaryEngineer ? event.filters.secondaryEngineer.value : null;
			}
			if (event.filters.buyerCode && event.filters.buyerCode.value) {
				this.filterOptions['buyerCDSID'] = event.filters.buyerCode ? event.filters.buyerCode.value : null;
			}
			if (event.filters.copyFrom && event.filters.copyFrom.value) {
				this.filterOptions['copyFrom'] = event.filters.copyFrom ? event.filters.copyFrom.value : null;
			}
			this.filterOptions['supplierOrBuyer'] = this.isSupplier();
		}

		if (event === undefined && this.dataTable.filters && Object.keys(this.dataTable.filters).length !== 0) {
			if (this.dataTable.filters.rfiId) {
				this.filterOptions['rfi'] = (this.dataTable.filters.rfiId as HTMLInputElement).value;
			}
			if (this.dataTable.filters.psa) {
				this.filterOptions['psa'] = (this.dataTable.filters.psa as HTMLInputElement).value;
			}
			if (this.dataTable.filters.partNumber) {
				this.filterOptions['partNumber'] = (this.dataTable.filters.partNumber as HTMLInputElement).value;
			}
			if (this.dataTable.filters.cmfDCR) {
				this.filterOptions['cmfDCR'] = (this.dataTable.filters.cmfDCR as HTMLInputElement).value;
			}
			if (this.dataTable.filters.cmfDCRTitle) {
				this.filterOptions['cmfDCRTitle'] = (this.dataTable.filters.cmfDCRTitle as HTMLInputElement).value;
			}
			if (this.dataTable.filters.rfiModifiedDate) {
				this.filterOptions['rfiModifiedDate'] = (this.dataTable.filters.rfiModifiedDate as HTMLInputElement).value;
			}
			if (this.dataTable.filters.status) {
				this.filterOptions['rfiStatus'] = (this.dataTable.filters.status as HTMLInputElement).value;
			}
			if (this.dataTable.filters.leadPrg) {
				this.filterOptions['program'] = (this.dataTable.filters.leadPrg as HTMLInputElement).value;
			}
			if (this.dataTable.filters.supplier) {
				this.filterOptions['supplier'] = (this.dataTable.filters.supplier as HTMLInputElement).value;
			}
			if (this.dataTable.filters.supplierCode) {
				this.filterOptions['supplierCode'] = (this.dataTable.filters.supplierCode as HTMLInputElement).value;
			}
			if (this.dataTable.filters.purCommCode) {
				this.filterOptions['commodity'] = (this.dataTable.filters.purCommCode as HTMLInputElement).value;
			}
			if (this.dataTable.filters.rfiCreatedDate) {
				this.filterOptions['rfiCreatedDate'] = (this.dataTable.filters.rfiCreatedDate as HTMLInputElement).value;
			}
			if (this.dataTable.filters.costEstimator) {
				this.filterOptions['costEstimator'] = (this.dataTable.filters.costEstimator as HTMLInputElement).value;
			}
			if (this.dataTable.filters.secondaryEngineer) {
				this.filterOptions['secondaryEngineer'] = (this.dataTable.filters.secondaryEngineer as HTMLInputElement).value;
			}
			if (this.dataTable.filters.buyerCode) {
				this.filterOptions['buyerCDSID'] = (this.dataTable.filters.buyerCode as HTMLInputElement).value;
			}
			this.filterOptions['supplierOrBuyer'] = this.isSupplier();
		}
	}

	private updateRFIDataList(onLoad) {
		if (!this.selectedProgram && !this.selectedCommodity && !this.selectedBuyerCDSID
			&& !this.selectedRFI && !this.selectedChangeRequest && !this.selectedPSA && !this.selectedSupplier
			&& !this.selectedRFIStatus && !this.selectedDrCDSID && !this.selectedCostEstimator && !this.selectedSecondaryEngineer && !this.selectedCMFDCR
			&& !this.selectedSupplierCode && !this.selectedPartNum && (!this.rangeDates || this.rangeDates.length === 0)
			&& !this.selectedOrganizationDomain) {
			sessionStorage.removeItem('searchRfi');
			onLoad = true;
		}

		if (onLoad) {
			this.rfiRequests = this.retriveRfiRequests(this.rfiRequestsOrg);
		} else {
			//this.searchRfi = 'true';
			sessionStorage.setItem('searchRfi', 'true');
			this.rfiRequests = this.retriveRfiRequests(this.rfiRequestsOrgAll);
		}
	}

	private updateStatusString() {
		const buyerSupplier = this.buyerSupplier.toLowerCase() === 'buyer' ? 'Buyer Home Page' : 'Supplier Home Page';
		let statusString = this.status === 'U' ? 'Unread RFIS' : (this.status === 'I' ? 'In Progress RFIs' : 'Completed RFIs');
		const program = this.program;

		if (program && program !== 'null') {
			statusString = this.status === 'U' ? 'PIE Unread RFIS' : (this.status === 'I' ? 'PIE In Progress RFIs' : 'PIE Completed RFIs');
		}

		let statusUrl = '#/rfi-request/' + this.buyerSupplier + '/program/' + program + '/' + this.status;
		if (program === 'all') {
			statusString =  this.buyerSupplier.toLowerCase() === 'buyer' ? 'Manage RFIs/RFQs' : 'Manage RFIs/RFQs';
			// tslint:disable-next-line:no-shadowed-variable
			const supp = this.buyerSupplier.toLowerCase() === 'buyer' ? 'Buyer' : 'Supplier';
			statusUrl = '#/rfi-request/' + this.buyerSupplier + '/all/all/' + supp + 'SelectRFI' + '/false/false';
		}
		const buyerSupplierUrl = this.buyerSupplier.toLowerCase() === 'buyer' ? 'buyer' : 'supplier';


		this.breadCrumItems = [
			{label: buyerSupplier, url: '#/buyer-supplier/' + buyerSupplierUrl, target: '_self'},
			{label: statusString, target: '_self'},
		];

		if (program && program !== 'null' && program !== 'all') {
			if (statusString === 'PIE Completed RFIs') {
				this.breadCrumItems.push({label: this.status, target: '_self'});
				this.breadCrumItems.push({label: program, target: '_self'});
			} else {
				this.breadCrumItems.push({label: program, target: '_self'});
			}
		}
	}

	private retriveRouteParameter(refresh, selectedType: string) {
		this.activatedRoute.paramMap.subscribe(params => {
			this.buyerSupplier = params.get('buyerSupplier');
			this.filterName = params.get('filterName');
			this.filterBy = params.get('filterBy');
			this.status = params.get('status');
			this.back = params.get('back');
			this.searchRfi = params.get('searchRfi');

			const supp = this.isSupplier();
			let userId = supp ? sessionStorage.getItem('emailAddress') : sessionStorage.getItem('userId');
			const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
			/*#### need to add back apsroles if we comment org  Code Related to Supplier own RFI organization Functionlaity ###*/
			if (apsRoles && this.isAdminRole()) {
				userId = 'eDCM_IT_ADMIN';
			}

			if (apsRoles && apsRoles.includes('EDCM_VIEW_ONLY')) {
				userId = 'EDCM_VIEW_ONLY';
			}

			this.rfiService.findAllCMFR(supp, userId).subscribe(response => {
				this.cmfrDCRList = response;
			});

			if (this.status) {
				if (this.status === 'U') {
					this.statusName = 'Unread';
				} else if (this.status === 'I') {
					this.statusName = 'In Progress';
				} else {
					this.statusName = 'Completed';
					if (this.status === 'BuyerSelectRFI' || this.status === 'SupplierSelectRFI' || this.status === 'undefined') {
						this.statusName = 'RFI/RFQ FILTER';
					}
				}
			} else {
				this.statusName = this.filterBy;
			}

			if (this.filterName === 'dateRange') {
				this.statusName = this.statusName + ' (' + this.filterBy + ' days older)';
			}

			if (this.filterBy) {
				this.program = this.filterBy;
			}
			console.log('Program : ' + sessionStorage.getItem('program'));

			const filterOptions = {};
			this.updateSelectedValue(refresh);
			this.retriveRfiHeaders(this.filterName, this.filterBy, this.status, filterOptions);
		});
	}

	private isNotBlank(key) {
		return sessionStorage.getItem(key) && sessionStorage.getItem(key) !== 'null';
	}

	private updateSelectedValue(refresh) {

		if (this.back ==='true') {
			if (this.isNotBlank('rfi')) {
				this.selectedRFI = sessionStorage.getItem('rfi');
			}
			if (this.isNotBlank('psa')) {
				this.selectedPSA = sessionStorage.getItem('psa');
			}
			if (this.isNotBlank('partNumber')) {
				this.selectedPartNum = sessionStorage.getItem('partNumber');
			}
			if (this.isNotBlank('program')) {
				this.selectedProgram = sessionStorage.getItem('program');
			}
			if (this.isNotBlank('commodity')) {
				this.selectedCommodity = sessionStorage.getItem('commodity');
			}
			if (this.isNotBlank('costEst')) {
				this.selectedCostEstimator = sessionStorage.getItem('costEst');
			}
			if (this.isNotBlank('secondaryeng')) {
				this.selectedSecondaryEngineer = sessionStorage.getItem('secondaryeng');
			}
			if (this.isNotBlank('rfiStatus')) {
				this.selectedRFIStatus = sessionStorage.getItem('rfiStatus');
			}
			if (this.isNotBlank('supplier')) {
				this.selectedSupplier = sessionStorage.getItem('supplier');
			}
			if (this.isNotBlank('cmfDCRTitle')) {
				this.selectedChangeRequest = sessionStorage.getItem('cmfDCRTitle');
			}
			if (this.isNotBlank('siteCode')) {
				this.selectedSupplierCode = sessionStorage.getItem('siteCode');
			}
			if (this.isNotBlank('buyer')) {
				this.selectedBuyerCDSID = sessionStorage.getItem('buyer');
			}
			if (this.isNotBlank('pdEngg')) {
				this.selectedDrCDSID = sessionStorage.getItem('pdEngg');
			}
			if (this.isNotBlank('cmfDCR')) {
				this.selectedCMFDCR = sessionStorage.getItem('cmfDCR');
			}
			if (this.isNotBlank('rangeDates')) {
				const ranges = JSON.parse(sessionStorage.getItem('rangeDates'));
				if (ranges && ranges.length > 0 && ranges[0] != null) {
					this.rangeDates = [];
					this.rangeDates[0] = new Date(ranges[0]);
					if (ranges.length > 1 && ranges[1] != null) {
						this.rangeDates[1] = new Date(ranges[1]);
					}
				}

			}

		} else if (!refresh) {
			sessionStorage.setItem('program', null);
			sessionStorage.setItem('commodity', null);
			sessionStorage.setItem('costEst', null);
			sessionStorage.setItem('secondaryeng', null);
			sessionStorage.setItem('rfi', null);
			sessionStorage.setItem('rfiStatus', null);
			sessionStorage.setItem('psa', null);
			sessionStorage.setItem('supplier', null);
			sessionStorage.setItem('cmfDCRTitle', null);
			sessionStorage.setItem('partNumber', null);
			sessionStorage.setItem('siteCode', null);
			sessionStorage.setItem('buyer', null);
			sessionStorage.setItem('cmfDCR', null);
			sessionStorage.setItem('pdEngg', null);
			this.selectedPartNum = null;
			this.selectedSupplierCode = null;
			this.selectedBuyerCDSID = null;
			this.selectedDrCDSID = null;
			this.selectedCostEstimator = null;
			this.selectedSecondaryEngineer = null;
			this.selectedStatus = null;
			this.selectedPSA = null;
			this.selectedRFI = null;
			this.selectedRFIStatus = null;
			this.selectedStatus = null;
			this.selectedCMFDCR = null;
			this.selectedSupplier = null;
			this.selectedChangeRequest = null;
			this.rangeDates = null;
		}
	}

	onRfiTypeChange() {
		this.retriveRouteParameter(false, this.selectedType);
	}
}
