import {Component, OnInit} from '@angular/core';

/** Component that has no functionality other than plain html text in the template */
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	/** empty constructor */
	constructor() {
	}

	/** used for functionality that doesn't belong in the constructor but still
	 * needs to be run at the beginning of the lifecycle of the component.
	 */
	ngOnInit() {
	}

}
