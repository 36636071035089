/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RfiRequestService {

	private config = new Config();

	// tslint:disable-next-line:indent
	constructor(private http: HttpClient) {
	}

	findAll(supplier, userId, role, page, size, sortColumn, sortOrder, selectedType,filetrOptions): Observable<any> {
		if (!sortColumn) {
			sortColumn = 'No column';
		}
		const url = `${environment.BACKEND_URL}edcmservice/rfiHeader/list/${supplier}/${userId}/${role}/${page}/${size}/${sortColumn}/${sortOrder}/${selectedType}`;

		return this.http.post(url, filetrOptions);
	}

	findAllCMFR(supplier, userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/listCMFDCR/' + supplier + '/' + userId);
	}

	findAllRfiData(supplier, userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/listRfiData/' + supplier + '/' + userId);
	}

	findRfiListForPsa(psa): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/rfiList/' + psa);
	}

	findRfiForPsa(psa): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/rfiForPsa/' + psa);
	}

	findRfiListForUser(userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/rfiListForUser/' + userId);
	}

	programCount(supplier, userId, role): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/programCount/' + supplier + '/' + userId + '/' + role);
	}

	findById(id: string): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/' + id);
	}

	updateStatusById(rfiPrefix, rfiNum, status): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/rfiHeader/updateStatus/' + rfiPrefix + '/' + rfiNum + '/' + status, null);
	}

	updateSupplierStatus(rfiPrefix, rfiNum): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/rfiHeader/updateSupplierStatus/' + rfiPrefix + '/' + rfiNum, null);
	}

	updateExtReqCount(rfiPrefix, rfiNum, suuplier): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/rfiHeader/updateExtReqCount/' + rfiPrefix + '/' + rfiNum + '/' + suuplier, null);
	}

	updateRFI(rfiPrefix, rfiNum, rfi, isSupplier): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/rfiHeader/updateRFI/' + rfiPrefix + '/' + rfiNum + '/' + isSupplier, rfi);
	}

	downloadAttachment(cmfNumber, part, fileName, sourceSystem): Observable<any> {
		return this.http
			.get(`${environment.BACKEND_URL}edcmservice/attachments/${sourceSystem}?cmfDcrNumber=${cmfNumber}
			&part=${part}&fileName=${encodeURIComponent(fileName)}`, {responseType: 'blob'});
	}

	fetchGsdbList(psa): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHeader/gsdbList/' + psa);
	}

	saveRFIInternalCostDetails(rfiData): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/rfiHeader/saveRFIInternalCostDetails', rfiData);
	}
}
