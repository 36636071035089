/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {PartialRfiDraftRequestService} from '../services/rfi/partial-rfi-draft-request.service';

@Component({
	selector: 'app-partial-draft-rfi-request',
	templateUrl: './partial-draft-rfi-request.component.html',
	styleUrls: ['./partial-draft-rfi-request.component.css']
})
export class PartialDraftRfiRequestComponent implements OnInit {
	columns = [];
	rfiHeaderFilter = {};

	rfiRequests: any [] = [];
	rfiRequestsOrg: any [] = [];
	rfiRequestsOrgAll: any [] = [];

	selectedRFI = null;
	selectedPSA = null;
	selectedPartNum = null;
	selectedChangeRequest = null;
	rangeDates: Date[];
	selectedRFIStatus = null;
	selectedStatus = null;
	selectedProgram = null;
	selectedSupplier = null;
	selectedSupplierCode = null;
	selectedCommodity = null;
	selectedBuyerCDSID = null;
	selectedStat = null;

	programList = [];
	commodityList = [];
	partNumberList = [];
	supplierCodeList = [];
	buyerCDSIDCodeList = [];
	statusList = [];

	buyerSupplier: any;
	filterName: any;
	filterBy: any;
	statusName: any = 'Unread RFIs';

	progSelectDisabled = false;
	dateRangeSelectDisabled = false;
	detailDialog = false;
	dialogDetails = null;
	detailsRfiId = null;
	status;
	back = 'false';
	searchRfi = 'false';
	rfis = [];
	filteredRoles: string[] = [];

	private breadCrumItems: MenuItem[];

	constructor(private activatedRoute: ActivatedRoute, private router: Router,
				private partialDraftRfiService: PartialRfiDraftRequestService,
				private messageService: MessageService) {
	}

	ngOnInit() {
		this.retriveRouteParameter();

		this.columns = [
			{field: 'partNumber', header: 'Part Number/Material Number'},
			{field: 'requestNo', header: 'CMF/DCR #'},
			{field: 'fourDigitPlantNo', header: 'MP&L Plant Code(E3P)'},
			{field: 'plant', header: 'FEDEBOM Plant Code'},
			{field: 'psaNumber', header: 'PSA #'},
			{field: 'modifiedBy', header: 'Saved By'},
			{field: 'modifiedDate', header: 'Saved Date'}
		];

		this.filteredRoles = ['JV Engineer', 'JV Buyer', 'Buyer', 'PD_Engineer'];

		this.breadCrumItems = [
			{label: 'Buyer Home Page', url: '#/buyer-supplier/buyer', target: '_self'},
			{label: 'Partial RFI/RFQ', url: '#/partial-draft-rfi-request', target: '_self'}
		];
	}

	onEdit(rowData) {
		this.router.navigate(['create-partial-rfis/' + rowData.id]);
	}

	deleteRow(rowData) {
		this.partialDraftRfiService.deleteById(rowData.id).subscribe(response => {
			this.retriveRfiHeaders();
			this.messageService.add({
				severity: 'success',
				summary: 'Success',
				detail: 'Selected Partial Draft RFI/RFQ deleted successfully.'
			});
		});
	}

	createRFI() {
		this.router.navigate(['create-partial-rfis/' + null]);
	}

	onFilter(event, dt) {
		const filteredValues = event.filteredValue;
		console.log(filteredValues);
	}

	isAdminRole(apsRoles) {
		return apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('eDCM_Super_Buyer') || apsRoles.includes('Buyer') || apsRoles.includes('JV Engineer')
			|| apsRoles.includes('JV Buyer');
	}

	private isSupplier() {
		return this.buyerSupplier.toLowerCase() === 'supplier';
	}

	private isBuyer() {
		return this.buyerSupplier.toLowerCase() === 'buyer';
	}

	private retriveRfiHeaders() {
		// const supp = this.isSupplier();
		// let userId = supp ? sessionStorage.getItem('emailAddress') : sessionStorage.getItem('userId');
		let userId = sessionStorage.getItem('userId');
		const currentUser = userId;
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles && this.isAdminRole(apsRoles)) {
			userId = 'eDCM_IT_ADMIN';
		}

		if (apsRoles && apsRoles.includes('EDCM_VIEW_ONLY')) {
			userId = 'EDCM_VIEW_ONLY';
		}

		this.partialDraftRfiService.findAll(false, userId).subscribe(result => {
			if (this.filteredRoles.find((role) => role.includes(apsRoles[0]))) {
				this.rfiRequests = result.filter((req) => req.createdBy === currentUser);
			} else {
				this.rfiRequests = result;
			}
		});
	}

	private retriveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.retriveRfiHeaders();
		});
	}
}
