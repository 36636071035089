import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'permission-denied',
	templateUrl: './permission-denied.component.html',
	styleUrls: ['./permission-denied.component.css']
})
export class PermissionDeniedComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
