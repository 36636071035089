/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {SimpleResponse} from '../../models/SimpleResponse';

@Injectable({
	providedIn: 'root'
})
export class PartialRfiRequestService {

	private config = new Config();

	// tslint:disable-next-line:indent
	constructor(private http: HttpClient) {
	}

	findAll(): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/partial-rfi');
	}

	findPDSystemData(cmf, partPrefix, partBase, partSuffix, plat5Digit, plat4Digit): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL +
				'edcmservice/partial-rfi/pdSystem/' + cmf + '/' + partPrefix + '/' + partBase + '/' +
				partSuffix + '/' + plat5Digit + '/' + plat4Digit);

	}

	saveADSData(adsData): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/adsData', adsData);
	}

	savePsaBuyerData(psaBuyyerData): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/psaBuyer', psaBuyyerData);
	}

	isADSDataSaved(adsData): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/adsData/isSaved', adsData);
	}

	isPdSystemDataExisting(pdSystem): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/pdSystem/isExisted', pdSystem);
	}

	isErrorLogExpired(pdSystem): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/pdSystem/isExpired', pdSystem);
	}

	savePdSystemData(pdSystem): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/pdSystem', pdSystem);
	}

	updateInetgrationErrorLog(cmf, partPrefix, partBase, partSuffix, plat5Digit, plat4Digit): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/integrationErrorLog/' + cmf + '/' +
				partPrefix + '/' + partBase + '/' + partSuffix + '/' + plat5Digit + '/' + plat4Digit, null);
	}

	validateCostEstimator(costEstimatorMap: {}) {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/partial-rfi/validateCostestimator', costEstimatorMap);

	}

	validateBuyer(costEstimatorMap: {}) {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/partial-rfi/validateBuyer', costEstimatorMap);

	}

	validatePsaBuyer(costEstimatorMap: {}) {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/partial-rfi/validatePsaBuyer', costEstimatorMap);

	}

	validateGsdbCode(costEstimatorMap: {}) {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/partial-rfi/validateGsdbCode', costEstimatorMap);

	}
	saveRFIInternalCostDetailsPartial(pdSystem): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/partial-rfi/pdSystemInternalCost', pdSystem);
	}

}
