import {Component, OnInit} from '@angular/core';
import {BannerService} from '../../services/rfi/banner.service';


@Component({
	selector: 'app-banner-message',
	templateUrl: './banner-message.component.html',
	styleUrls: ['./banner-message.component.scss']
})
export class BannerMessageComponent implements OnInit {

	bannerMessage: string;
	notificationMessage: string;

	constructor(private bannerService: BannerService) {
	}

	ngOnInit() {
		this.bannerService.bannerMessageSubject.subscribe((message) => {
			this.bannerMessage = message;
		});

		this.bannerService.notificationSubject.subscribe((notification) => {
			this.notificationMessage = notification;
		});
	}

}
