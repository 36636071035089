/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RfiHistoryRequestService {

	constructor(private http: HttpClient) {
	}

	findAll(rfiPrefix, rfiId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiHistory/' + rfiPrefix + '/' + rfiId);
	}

	saveHistory(rfiHistory): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/rfiHistory', rfiHistory);
	}
}
