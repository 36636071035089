/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */

export const environment = {
	envName: 'dev',
	production: false,
	ADFS_URL: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	ADFS_CLIENT_ID: 'urn:edcm_preprod:clientid:web_edcm_preprod_website_gcp:dev',
	ADFS_RESOURCE: 'urn:edcm_preprod:resource:web_edcm_preprod_website_gcp:dev',
	BACKEND_URL: 'https://api.d01e.gcp.ford.com/',
	// include development api base url string here as a property
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
