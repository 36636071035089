/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {SimpleResponse} from '../../models/SimpleResponse';

@Injectable({
	providedIn: 'root'
})
export class BuddyBuyerService {

	private config = new Config();

	constructor(private http: HttpClient) {
	}

	findAll(userId, page, size, sortColumn, sortOrder, filetrOptions): Observable<any> {
		if (!sortColumn) {
			sortColumn = 'updateDate';
		}
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/buddybuyer' + '/all/' + userId + '/' +
				page + '/' + size + '/' + sortColumn + '/' + sortOrder, filetrOptions);
	}

	findAllBuyers(): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/buddybuyer/buyers');
	}

	findBuddyBuyer(buddyBuyerId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/buddybuyer/' + buddyBuyerId);
	}

	saveBuddyBuyer(buddyBuyer): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/buddybuyer/', buddyBuyer);
	}

	bulkUpdateBuddyBuyers(buddyBuyerMap): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/buddybuyer/bulkUpdate', buddyBuyerMap);
	}

	validateBuyer(buddyBuyerMap): Observable<SimpleResponse> {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/buddybuyer/validateBuyer', buddyBuyerMap);
	}
}
