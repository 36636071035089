import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import moment from 'moment';
import {BuddyBuyerService} from '../../services/rfi/buddyBuyer.service';

@Component({
	selector: 'create-buddy-buyer',
	templateUrl: './create-buddy-buyer.component.html',
	providers: [MessageService]
})
export class CreateBuddyBuyerComponent implements OnInit {

	minDate: Date | undefined;
	private buddyBuyerId;
	private mode;
	private isEdit;
	private buddyBuyer: any = {};
	private breadCrumItems: MenuItem[];
	private isFromDateChange = false;
	private isToDateChange = false;
	private orgFromDate;
	private orgToDate;
	private buyerList = [];
	private selectedBuddyBuyer;
	private isSubmit;
	private isBuyer = false;
	private validationMessage;
	private buddyBuyerMap = {};

	constructor(private activatedRoute: ActivatedRoute, private buddyBuyerService: BuddyBuyerService,
				private messageService: MessageService, private confirmationService: ConfirmationService,
				private fb: FormBuilder) {

	}

	ngOnInit(): void {
		const that = this;
		// tslint:disable-next-line:no-unused-expression
		this.buddyBuyer.isMoving = 'Moving';
		that.retrieveRouteParameter();
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month - 1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);

		this.breadCrumItems = [
			{label: 'Buyer Home Page', url: '#/buyer-supplier/buyer', target: '_self'},
			{label: 'Update Buyer', url: '#/buddy-buyer', target: '_self'},
			{label: 'Edit Buyer'}
		];
	}

	isValidDates(fromDate, toDate) {
		return moment(fromDate) < moment(toDate);
	}

	isMandatoryField() {
		if (this.buddyBuyer.isMoving === 'Moving') {
			const mandatory = this.buddyBuyer.fromDate && this.buddyBuyer.buddyBuyer;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.buddyBuyer.isMoving === 'Vacation') {
			const mandatory = this.buddyBuyer.toDate && this.buddyBuyer.fromDate && this.buddyBuyer.buddyBuyer;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}

	private onFromDateChange() {
		this.isFromDateChange = true;
	}

	private onToDateChange() {
		this.isToDateChange = true;
	}

	private onSave() {
		if (!this.isMandatoryField()) {
			this.validationMessage = 'Buyer is required';
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}

		if (!this.isFromDateChange && this.orgFromDate && this.buddyBuyer.isMoving === 'Vacation') {
			this.buddyBuyer.fromDate = this.orgFromDate;
		}

		if (!this.isFromDateChange && this.buddyBuyer.isMoving === 'Moving') {
			this.buddyBuyer.fromDate = null;
		}

		if (!this.isToDateChange) {
			this.buddyBuyer.toDate = null;
		}

		if (this.buddyBuyer.isMoving === 'Moving') {
			this.buddyBuyer.toDate = null;
		}

		if (!this.buddyBuyer.buddyBuyer) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please enter valid buyer CDSID.'
				});
			}, 30);

			return;
		}

		const regexp = new RegExp('^[A-Za-z0-9]*$');

		if (!regexp.test(this.buddyBuyer.buddyBuyer)) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please enter valid buyer CDSID.'
				});
			}, 30);

			return;
		}

		if (this.buddyBuyer.isMoving === 'Vacation' && !this.buddyBuyer.toDate) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else if (this.buddyBuyer.isMoving === 'Moving' && !this.buddyBuyer.fromDate) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else {

			if (this.buddyBuyer.isMoving === 'Vacation' && !this.isValidDates(this.buddyBuyer.fromDate, this.buddyBuyer.toDate)) {
				setTimeout(() => {
					this.messageService.add({
						life: 300000, severity: 'warn',
						summary: 'From date must be smaller than To date for Buddy Buyer.'
					});
				}, 30);
				return;
			}
			this.buddyBuyer.updateBy = sessionStorage.getItem('userId');
			this.buddyBuyerMap['buddyBuyer'] = this.buddyBuyer.buddyBuyer;
			this.buddyBuyerMap['updatedBy'] = sessionStorage.getItem('userId');
			this.buddyBuyerService.validateBuyer(this.buddyBuyerMap).subscribe(responseUpdate => {
				if (responseUpdate.status === 'Failure') {
					this.buddyBuyerService.saveBuddyBuyer(this.buddyBuyer).subscribe(response => {
						if (response) {
							setTimeout(() => {
								this.messageService.add({
									life: 300000, severity: 'success',
									summary: 'Buyer ID saved successfully. Please allow at least 15 minutes for the changes to take effect.'
								});
							}, 30);
							this.buddyBuyer.fromDate = moment(this.buddyBuyer.fromDate).format('MM/DD/YYYY');
							if (this.buddyBuyer.toDate) {
								this.buddyBuyer.toDate = moment(this.buddyBuyer.toDate).format('MM/DD/YYYY');
							}
							this.selectedBuddyBuyer = this.buddyBuyer.buddyBuyer;
						}
					});
				}
			});
		}
	}

	private fetchBuddyBuyer() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.buddyBuyerService.findBuddyBuyer(this.buddyBuyerId).subscribe(resObj => {
			this.buddyBuyer = resObj;
			this.buddyBuyer.isMoving = 'Moving';
			this.orgFromDate = this.buddyBuyer.fromDate;

			if (this.buddyBuyer.fromDate) {
				this.buddyBuyer.fromDate = moment(this.buddyBuyer.fromDate).format('MM/DD/YYYY');
			}

			if (this.buddyBuyer.toDate) {
				this.buddyBuyer.isMoving = 'Vacation';
				this.orgToDate = this.buddyBuyer.toDate;
				this.buddyBuyer.toDate = moment(this.buddyBuyer.toDate).format('MM/DD/YYYY');
			}

			this.buddyBuyerService.findAllBuyers().subscribe(buyers => {
				this.buyerList.push({label: 'Select Buyer', value: null});

				buyers.forEach(buyerObj => {
					this.buyerList.push({label: buyerObj.name, value: buyerObj.name});
				});

				this.selectedBuddyBuyer = this.buddyBuyer.buddyBuyer;
			});
		});
	}

	private onBuyerChange() {
		this.buddyBuyer.buddyBuyer = this.selectedBuddyBuyer;
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.buddyBuyerId = params.get('buddyBuyerId');
			this.mode = params.get('mode');
			this.isEdit = this.mode === 'edit';

			const addEdit = this.isEdit ? 'Edit Buddy Buyer' : 'Add Buddy Buyer';

			if (this.isEdit) {
				this.fetchBuddyBuyer();
			}
		});
	}
	private validateBuyer(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.buddyBuyerMap['buddyBuyer'] = this.buddyBuyer.buddyBuyer;
			this.buddyBuyerMap['updatedBy'] = sessionStorage.getItem('userId');

			this.buddyBuyerService.validateBuyer(this.buddyBuyerMap).subscribe(response => {
				if (response.status === 'Success') {
					this.isBuyer = true;
					this.validationMessage = response.validationMessage;
					resolve(true); // Resolve with true if validation is successful
				} else {
					this.isBuyer = false;
					resolve(false); // Resolve with false if validation fails
				}
			});
		});
	}
	restrictSpecialCharacters(event: KeyboardEvent) {
		const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
		if (!allowedKeys.includes(event.key) && !/[a-zA-Z0-9\s]/.test(event.key)) {
			event.preventDefault();
		}
	}

}
