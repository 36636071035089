/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {HelpDocumentService} from '../../services/rfi/help-document.service';

@Component({
	selector: 'app-help-document-download',
	templateUrl: './download.component.html',
	styleUrls: ['./download.component.css'],
	providers: [MessageService]
})
export class HelpDocumentDownloadComponent implements OnInit {

	documentList = [];

	constructor(private helpDocumentService: HelpDocumentService) {
	}

	ngOnInit() {
		this.getDocumentList();
	}

	isInternalRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('PD_Engineer') || apsRoles.includes('JV Engineer')
			|| apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer')
			|| apsRoles.includes('eDCM_Super_Buyer') || apsRoles.includes('EDCM_VIEW_ONLY')
			|| apsRoles.includes('CostEstimator') || apsRoles.includes('JV Costestimator')
			|| apsRoles.includes('eDCM_IT_ADMIN');
	}

	downloadFile(fileName) {
		this.helpDocumentService.downloadDocument(fileName).subscribe(response => {
			const blob = new Blob([response]);
			const downloadUrl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = fileName;
			a.click();
		});
	}

	private getDocumentList() {
		this.helpDocumentService.showDocumentList().subscribe(response => {
			this.documentList = response;
		});
	}
}
