/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {ExcelService} from '../services/excel.service';
import {BuddyBuyerService} from '../services/rfi/buddyBuyer.service';
import moment from 'moment';

@Component({
	selector: 'app-buddy-buyer',
	templateUrl: './buddy-buyer.component.html',
	styleUrls: ['./buddy-buyer.component.css'], providers: [MessageService]
})
export class BuddyBuyerComponent implements OnInit {
	loading = true;
	filterOptions: {};
	totalRecords: number;
	minDate: Date | undefined;
	private columns = [];
	private buddyBuyers = [];
	private buddyBuyersExcel = [];
	private selectedBuddyBuyer;
	private breadCrumItems: MenuItem[];
	private dtSelectedRows = [];
	private isMoving = 'Moving';
	private buddyBuyerName;
	private fromDate;
	private toDate;
	private buddyBuyerMap = {};
	private isSubmit;
	private isBuyer = false;

	private validationMessage;

	constructor(private router: Router,
				private buddyBuyerService: BuddyBuyerService, private messageService: MessageService,
				private excelService: ExcelService) {
	}

	ngOnInit() {
		this.filterOptions = {};
		this.retriveBuddyBuyer(this.filterOptions);

		this.columns = [
			{field: 'psa', header: 'PSA#'},
			{field: 'program', header: 'Lead Program'},
			{field: 'commodity', header: 'Material Group'},
			{field: 'commodityName', header: 'Material Group Description'},
			{field: 'buyer', header: 'Buyer'},
			{field: 'buddyBuyer', header: 'Updated Buyer'},
			{field: 'fromDate', header: 'From Date'},
			{field: 'toDate', header: 'To Date'},
			{field: 'updateBy', header: 'Updated By'},
			{field: 'updateDate', header: 'Updated Date'},
			{field: 'status', header: 'Reason'}
		];
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month - 1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);

		this.breadCrumItems = [
			{label: 'Buyer Home Page', url: '#/buyer-supplier/buyer', target: '_self'},
			{label: 'Update Buyer', url: '#/buddy-buyer', target: '_self'}
		];
	}

	onFilter(event: any, table: any): void {
		if (!!event.filteredValue) {
			this.buddyBuyersExcel = event.filteredValue;
		}
	}

	selectAll(event: any) {
		if (event.checked) {
			this.dtSelectedRows = this.buddyBuyers;
		} else {
			this.dtSelectedRows = [];
		}

	}

	onRowSelect(data) {
		this.router.navigate(['create-buddy-buyer/edit/' + data.id]);
	}

	addBuddyBuyer() {
		this.router.navigate(['create-buddy-buyer/create/' + null]);
	}

	onRowSelection(event) {
		this.dtSelectedRows.push(event.data);
	}

	onRowUnSelection(event) {
		this.dtSelectedRows = this.dtSelectedRows.filter(item => item !== event.data);
	}

	isPdEngg() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('PD_Engineer') || apsRoles.includes('JV Engineer');
	}

	retriveBuddyBuyer(filterOptions, event?: LazyLoadEvent) {
		this.loading = true;
		let page = 0;
		let size = 10;
		let sortColumn = '';
		let sortOrder = 1;

		if (event) {
			page = event.first / event.rows;
			size = event.rows;
			sortColumn = event.sortField;
			sortOrder = event.sortOrder;
		}

		if (event && event.filters) {
			filterOptions = {
				psa: event.filters.psa ? event.filters.psa.value : null,
				program: event.filters.program ? event.filters.program.value : null,
				commodity: event.filters.commodity ? event.filters.commodity.value : null,
				commodityName: event.filters.commodityName ? event.filters.commodityName.value : null,
				buyer: event.filters.buyer ? event.filters.buyer.value : null,
				buddyBuyer: event.filters.buddyBuyer ? event.filters.buddyBuyer.value : null,
				fromDate: event.filters.fromDate ? event.filters.fromDate.value : null,
				toDate: event.filters.toDate ? event.filters.toDate.value : null,
				updateBy: event.filters.updateBy ? event.filters.updateBy.value : null,
				updateDate: event.filters.updateDate ? event.filters.updateDate.value : null,
				status: event.filters.status ? event.filters.status.value : null
			};
		}

		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer') || apsRoles.includes('PD_Engineer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.buddyBuyerService.findAll(userId, page, size, sortColumn, sortOrder, filterOptions).subscribe(response => {
			this.loading = false;
			this.totalRecords = response.totalRecordsAll;
			this.buddyBuyers = response.all;
			this.buddyBuyersExcel = this.buddyBuyers;
		});
	}

	nullCheck(date) {
		return (!date && (date === undefined || date === null || date === 'null'));
	}

	exportAsXLSX() {
		const buddyBuyerExports = [];

		this.buddyBuyersExcel.forEach(buddyBuyer => {
			const buddyBuyerExport = {};
			buddyBuyerExport['PSA#'] = buddyBuyer.psa;
			buddyBuyerExport['Lead Program'] = buddyBuyer.program;
			buddyBuyerExport['Material Group'] = buddyBuyer.commodity;
			buddyBuyerExport['Material Group Description'] = buddyBuyer.commodityName;
			buddyBuyerExport['Buyer'] = buddyBuyer.buyer;
			buddyBuyerExport['Updated Buyer'] = buddyBuyer.buddyBuyer;
			buddyBuyerExport['From Date']
				= buddyBuyer.fromDate ? moment(buddyBuyer.fromDate).format('MM/DD/YYYY HH:MM') : buddyBuyer.fromDate;
			buddyBuyerExport['To Date'] = buddyBuyer.toDate ? moment(buddyBuyer.toDate).format('MM/DD/YYYY HH:MM') : buddyBuyer.toDate;
			buddyBuyerExport['Updated By'] = buddyBuyer.updateBy;
			buddyBuyerExport['Updated Date']
				= buddyBuyer.updateDate ? 	moment(buddyBuyer.updateDate).format('MM/DD/YYYY') : buddyBuyer.updateDate;
			buddyBuyerExport['Reason'] = buddyBuyer.status;

			buddyBuyerExports.push(buddyBuyerExport);
		});

		this.excelService.exportAsExcelFile(buddyBuyerExports, 'BuddyBuyers');
	}

	isValidDates(fromDate, toDate) {
		return moment(fromDate) < moment(toDate);
	}

	isMandatoryField() {
		if (this.isMoving === 'Moving') {
			const mandatory = this.fromDate && this.buddyBuyerName;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.isMoving === 'Vacation') {
			const mandatory = this.toDate && this.fromDate && this.buddyBuyerName;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}

	private onBulkUpdate() {

		if (!this.dtSelectedRows || this.dtSelectedRows.length === 0) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',

					summary: 'Please select PSA(s) from table below to update.'
				});
			}, 30);

			this.dtSelectedRows = [];
			return;
		}

		if (!this.isMandatoryField()) {
			this.validationMessage = 'CDSID is required';
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}

		if (this.isMoving === 'Moving') {
			this.toDate = null;
		}

		//this.buddyBuyerMap['isMoving'] = this.isMoving;
		this.buddyBuyerMap['fromDate'] = this.fromDate;
		this.buddyBuyerMap['toDate'] = this.toDate;
		this.buddyBuyerMap['buddyBuyer'] = this.buddyBuyerName;

		const ids = [];
		for (const dtSelectedRow of this.dtSelectedRows) {
			const id = {id: dtSelectedRow.id, psa: dtSelectedRow.psa, buyer: dtSelectedRow.buyer};
			ids.push(id);
		}

		this.buddyBuyerMap['ids'] = ids;
		this.buddyBuyerMap['updatedBy'] = sessionStorage.getItem('userId');
		const regexp = new RegExp('^[A-Za-z0-9]*$');

		if (!regexp.test(this.buddyBuyerName)) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please enter valid buyer CDSID.'
				});
			}, 30);
			return;
		}

		if (!this.buddyBuyerName) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please enter valid buyer CDSID.'
				});
			}, 30);
		} else if (this.isMoving === 'Vacation'
			&& (this.nullCheck(this.buddyBuyerMap['toDate']))) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else if (this.isMoving === 'Moving'
			&& (this.nullCheck(this.buddyBuyerMap['fromDate']))) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else {
			if (this.isMoving === 'Vacation' && !this.isValidDates(this.buddyBuyerMap['fromDate'], this.buddyBuyerMap['toDate'])) {
				setTimeout(() => {
					this.messageService.add({
						life: 300000, severity: 'warn',
						summary: 'From date must be smaller than To date for Buddy Buyer.'
					});
				}, 30);
				return;
			}
			this.buddyBuyerMap['buddyBuyer'] = this.buddyBuyerName;
			this.buddyBuyerMap['updatedBy'] = sessionStorage.getItem('userId');
			this.buddyBuyerService.validateBuyer(this.buddyBuyerMap).subscribe(responseUpdate => {
				if (responseUpdate.status === 'Failure') {
					this.buddyBuyerService.bulkUpdateBuddyBuyers(this.buddyBuyerMap).subscribe(response => {
						if (response) {
							this.retriveBuddyBuyer(this.filterOptions);
							this.dtSelectedRows = [];
							setTimeout(() => {
								this.messageService.add({
									life: 300000, severity: 'success',
									summary: 'Updated Buyer information saved successfully for all selected PSAs. ' +
										'Please allow at least 15 minutes for the changes to take effect.'
								});
							}, 30);
						}
					});
				}
			});
		}
	}
	restrictSpecialCharacters(event: KeyboardEvent) {
		const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
		if (!allowedKeys.includes(event.key) && !/[a-zA-Z0-9\s]/.test(event.key)) {
			event.preventDefault();
		}
	}
	private validateBuyer(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.buddyBuyerMap['buddyBuyer'] = this.buddyBuyerName;
			this.buddyBuyerMap['updatedBy'] = sessionStorage.getItem('userId');
			this.buddyBuyerService.validateBuyer(this.buddyBuyerMap).subscribe(response => {
				if (response.status === 'Success') {
					this.isBuyer = true;
					this.validationMessage = response.validationMessage;
					resolve(true); // Resolve with true if validation is successful
				} else {
					this.isBuyer = false;
					resolve(false); // Resolve with false if validation fails
				}
			});
		});
	}
}
