/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {UserIdService} from '../services/user-id.service.service';
import {ApsService} from '../services/aps.service';
import {Subject} from 'rxjs';

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).
 */
@Injectable()
export class UrlConsumerService implements CanActivate {


	apsUserRole = new Subject<String>();

	/** Creates an instance of the UrlConsumerService
	 *
	 * @param router route instance for current routing params
	 * @param location the angular location service for interacting with the browser location object
	 * @param idService the angular service for handling user ID
	 */
	constructor(private router: Router, private location: Location, private idService: UserIdService, private apsService: ApsService) {
	}

	/** the actual guard fuction. Parses the queryString and stores the params in sessionStorage.
	 * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		console.log('UrlConsumerService canActivate');
		const queryParamsObj = this.getQueryParams();

		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			//token is part 2 of a JWT (index 1)
			const accessToken = atob(queryParamsObj['access_token'].split('.')[1]);
			const jsonToken = JSON.parse(accessToken);
			console.log('UrlConsumerService : Token Received....');

			sessionStorage.setItem('strAccessToken', accessToken);
			sessionStorage.setItem('encodedAccessToken', queryParamsObj['access_token']);

			sessionStorage.setItem('tokenIssue', jsonToken.iat);
			sessionStorage.setItem('tokenExp', jsonToken.exp);
			sessionStorage.setItem('userId', jsonToken.CommonName);
			sessionStorage.setItem('userName', jsonToken.givenName);
			sessionStorage.setItem('fordSiteCode', jsonToken.fordSiteCode);
			let email = jsonToken.mail;
			email = email instanceof Array ? email[0] : email;
			sessionStorage.setItem('emailAddress', email);
			sessionStorage.setItem('uid', jsonToken.uid);
			sessionStorage.setItem('cdsid', jsonToken.uid);
			sessionStorage.setItem('fordSiteCode', jsonToken.fordSiteCode);
			sessionStorage.setItem('assessmentPersonName', jsonToken.givenName + ' ' + jsonToken.sn);
			if (sessionStorage.getItem('apsRoles') == null) {
				await this.apsService.getApsUserRoles().toPromise().then(response => {
					const apsRoles = response;
					sessionStorage.setItem('apsRoles', JSON.stringify(apsRoles));
					this.apsUserRole.next(JSON.stringify(apsRoles));

				});
			}
			this.idService.setUserID(jsonToken.CommonName);
			this.idService.setUserName(jsonToken.givenName);

			if (sessionStorage.getItem('apsRoles').length === 0 || sessionStorage.getItem('apsRoles') === '[]') {
				console.log('No access ');
				this.router.navigate(['/no-access']);
			} else if (sessionStorage.getItem('redirectURL')) {
				this.router.navigate([sessionStorage.getItem('redirectURL')]);
				return false;
			}

			// Redirect to originally requested URL
			return true;
		} else {
			console.error('Invalid Token');
		}

		return false;
	}

	/** Parses the technically malformed queryString to pick off the token and associated properties.
	 * @returns The queryString params in Object format, or null if the string was invalid.
	 */
	getQueryParams() {
		if (this.location.path(true).indexOf('access_token') === 0) {
			const queryString = this.location.path(true);

			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = queryString.split('&');
			const queryParamsObj = new Object();

			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				queryParamsObj[splitArray[0]] = splitArray[1];
			}
			return queryParamsObj;
		} else {
			return null;
		}

	}
}
