/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {RfiComm2PartbaseRequestService} from '../services/rfi/rfi-comm2-partbase-request.service';
import {ExcelService} from '../services/excel.service';
import moment from 'moment';

@Component({
	selector: 'app-comm2-partbase',
	templateUrl: './comm2-partbase.component.html'
})
export class Comm2PartbaseRequestComponent implements OnInit {
	private columns = [];
	private comm2parts = [];
	private comm2partsExcel = [];
	private selectedcomm2part;
	private breadCrumItems: MenuItem[];

	constructor(private activatedRoute: ActivatedRoute, private router: Router,
				private comm2PartService: RfiComm2PartbaseRequestService,
				private messageService: MessageService, private excelService: ExcelService) {
	}

	ngOnInit() {
		this.retriveComm2Parts();

		this.columns = [
			{field: 'partNumber', header: 'Part #'},
			{field: 'commodity', header: 'Material Group'},
			{field: 'commodityDesc', header: 'Material Group Description'},
			/*	{ field: 'createdBy', header: 'Crerated By' },
				{ field: 'createDate', header: 'Created Date'},*/
			{field: 'updateBy', header: 'Updated By'},
			{field: 'updateDate', header: 'Updated Date'}
		];
	}

	onFilter(event: any, table: any): void {
		if (!!event.filteredValue) {
			this.comm2partsExcel = event.filteredValue;
		}
	}

	onRowSelect(data) {
		this.router.navigate(['create-comm2-partbase/edit/' + data.partPref + '/' + data.partBase + '/' + data.partSuff]);
	}

	retriveComm2Parts() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.comm2PartService.findAll(userId).subscribe(response => {
			this.comm2parts = response.filter(res => res.commodity && res.commodity.includes('EDCM000'));
			this.comm2partsExcel = this.comm2parts;

			this.breadCrumItems = [
				{label: 'Buyer', url: '#/buyer-supplier/buyer'},
				{label: 'Manage commodities', url: '#/comm2-partbase'}
			];
		});
	}

	exportAsXLSX(): void {
		const rfiExports = [];
		console.log(this.selectedcomm2part);

		this.comm2partsExcel.forEach(rfi => {
			const rfiExport = {};
			rfiExport['Part'] = rfi.partNumber;
			rfiExport['Material Group'] = rfi.commodity;
			rfiExport['Material Group Description'] = rfi.commodityDesc;
			rfiExport['Updated By'] = rfi.updateBy;
			rfiExport['Updated Date'] = rfi.updateDate ? moment(rfi.updateDate).format('MM/DD/YYYY HH:MM') : rfi.updateDate;

			rfiExports.push(rfiExport);
		});

		this.excelService.exportAsExcelFile(rfiExports, 'Commodities');
	}
}
