/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RfiLookupRequestService {

	private config = new Config();

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiLookup');
	}
}
