import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';


@Injectable({
	providedIn: 'root'
})
export class HelpDocumentService {

	helpDocumentUrl = `${environment.BACKEND_URL + 'edcmservice'}/help-document`;

	constructor(private http: HttpClient) {
	}

	uploadDocument(fromData, category): Observable<any> {
		return this.http.post<any>(this.helpDocumentUrl + '/uploadFile/' + category, fromData);
	}

	showDocumentList(): Observable<any> {
		return this.http.get<any>(this.helpDocumentUrl);
	}

	deleteDocument(id) {
		return this.http.delete(this.helpDocumentUrl + '/' + id);
	}

	downloadDocument(fileName) {
		return this.http.get(this.helpDocumentUrl + '/files/' + fileName, {responseType: 'blob'});
	}

}
