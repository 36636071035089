import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import moment from 'moment/moment';
import {SecondDandrService} from '../../services/rfi/second-dandr.service';

@Component({
	selector: 'create-second-dandr',
	templateUrl: './create-second-dandr.component.html',
	styleUrls: ['./create-second-dandr.component.css']
})
export class CreateSecondDandrComponent implements OnInit{
	private seconddandrId;
	private mode;
	private isEdit;
	private seconddandr: any = {};
	private breadCrumItems: MenuItem[];
	private isFromDateChange = false;
	private isToDateChange = false;
	private orgFromDate;
	private orgToDate;
	private buyerList = [];
	private selectedSecondDandR;
	private secondDandRMap = {};
	private isSecondDandR = false;
	private validationMessage;
	private isSubmit;
	minDate: Date | undefined;
	constructor(private activatedRoute: ActivatedRoute, private secondDandrService: SecondDandrService,
				private messageService: MessageService, private confirmationService: ConfirmationService,
				private fb: FormBuilder) {

	}

	ngOnInit(): void {
		const that = this;
		// tslint:disable-next-line:no-unused-expression
		this.seconddandr.isMoving = 'Moving';
		that.retrieveRouteParameter();
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month - 1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);
	}

	private onFromDateChange() {
		this.isFromDateChange = true;
	}

	private onToDateChange() {
		this.isToDateChange = true;
	}

	private onSave() {
		if (!this.isMandatoryField()) {
			this.validationMessage = 'SecondDandR is required';
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}

		if (this.seconddandr.isMoving === 'Moving') {
			this.seconddandr.toDate = null;
		}
		this.seconddandr.updateBy = sessionStorage.getItem('userId');
		this.seconddandr.createdBy = sessionStorage.getItem('userId');
		if (this.seconddandr.isMoving === 'Vacation' && !this.seconddandr.toDate) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set both From and To date for Update SecondDandR.'
			});
		} else if (this.seconddandr.isMoving === 'Moving' && !this.seconddandr.fromDate) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set From date for Update SecondDandR.'
			});
		} else {
			this.secondDandRMap['buddySeconddandr'] = this.seconddandr.buddySeconddandr;
			this.secondDandRMap['updatedBy'] = sessionStorage.getItem('userId');
			this.secondDandrService.validateSecondDandR(this.secondDandRMap).subscribe(responseUpdate => {
				if (responseUpdate.status === 'Failure') {
					if (!this.isFromDateChange) {
						this.seconddandr.fromDate = this.orgFromDate;
					}

					if (!this.isToDateChange) {
						this.seconddandr.toDate = this.orgToDate;
					}
					this.secondDandrService.saveSecondDandR(this.seconddandr).subscribe(response => {
						if (response) {
							this.seconddandr.fromDate = moment(this.seconddandr.fromDate).format('MM/DD/YYYY');
							if (this.seconddandr.toDate) {
								this.seconddandr.toDate = moment(this.seconddandr.toDate).format('MM/DD/YYYY');
								this.seconddandr.isMoving = 'Vacation';
							} else {
								this.seconddandr.isMoving = 'Moving';
							}
							this.selectedSecondDandR = this.seconddandr.secondDandR;
							this.messageService.add({
								severity: 'success',
								summary: 'Success',
								detail: 'SecondDandR ID saved successfully. Please allow at least 15 minutes for the changes to take effect.'
							});

						}
					});
				}
			});
		}
	}

	private fetchSecondDandR() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.secondDandrService.findSecondDandR(this.seconddandrId).subscribe(resObj => {
			this.seconddandr = resObj;
			this.seconddandr.isMoving = 'Moving';
			this.orgFromDate = this.seconddandr.fromDate;

			if (this.seconddandr.fromDate) {
				this.seconddandr.fromDate = moment(this.seconddandr.fromDate).format('MM/DD/YYYY');
			}

			if (this.seconddandr.toDate) {
				this.seconddandr.isMoving = 'Vacation';
				this.orgToDate = this.seconddandr.toDate;
				this.seconddandr.toDate = moment(this.seconddandr.toDate).format('MM/DD/YYYY');
			}


		});
	}

	private onBuyerChange() {
		this.seconddandr.seconddandr = this.selectedSecondDandR;
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.seconddandrId = params.get('seconddandrId');
			this.mode = params.get('mode');
			this.isEdit = this.mode === 'edit';

			const addEdit = this.isEdit ? 'Edit Update SecondDandR' : 'Add Update SecondDandR Contact';

			if (this.isEdit) {
				this.fetchSecondDandR();
			}

			this.breadCrumItems = [
				{label: 'Buyer Home Page', url: '#/buyer-supplier/buyer', target: '_self'},
				{label: 'Update SecondDandR', url: '#/second-dandr', target: '_self'},
				{label: 'Edit SecondDandR'}
			];
		});
	}

	private validationCheck() {
		this.secondDandRMap['buddySeconddandr'] = this.seconddandr.buddySeconddandr;
		this.secondDandRMap['updatedBy'] = sessionStorage.getItem('userId');
		this.secondDandrService.validateSecondDandR(this.secondDandRMap).subscribe(response => {
			if (response.status === 'Success') {
				this.isSecondDandR = true;
				this.validationMessage = response.validationMessage;
			} else {
				this.isSecondDandR = false;
			}
		});
	}

	isMandatoryField() {
		if (this.seconddandr.isMoving === 'Moving') {
			const mandatory = this.seconddandr.fromDate && this.seconddandr.buddySeconddandr;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.seconddandr.isMoving === 'Vacation') {
			const mandatory = this.seconddandr.toDate && this.seconddandr.fromDate && this.seconddandr.buddySeconddandr;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}
	restrictSpecialCharacters(event: KeyboardEvent) {
		const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
		if (!allowedKeys.includes(event.key) && !/[a-zA-Z0-9\s]/.test(event.key)) {
			event.preventDefault();
		}
	}
}
