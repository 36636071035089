/**
 * @author DCHIRUM1
 */
import {
	Component,
	OnInit
} from '@angular/core';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import moment from 'moment';
import {
	MenuItem,
	MessageService
} from 'primeng/api';
import { ExcelService } from '../services/excel.service';
import { CostEstimatorService } from '../services/rfi/cost-estimator.service';

@Component({
	selector: 'app-cost-estimator',
	templateUrl: './cost-estimator.component.html',
	styleUrls: ['./cost-estimator.component.css']
})
export class CostEstimatorComponent implements OnInit {
	private columns = [];
	private costEstimators = [];
	private costEstimatorExcel = [];
	private selectedBuddyCostEstimator;
	private breadCrumItems: MenuItem[];
	private dtSelectedRows;
	private isMoving = 'Moving';
	private costEstimator;
	private fromDate;
	private toDate;
	private costEstimatorMap = {};
	private isBuyer;
	private validationMessage;
	minDate: Date | undefined;
	private isSubmit;
	private isUpdateBuyer;

	constructor(private router: Router,
				private costEstimatorService: CostEstimatorService,
				private messageService: MessageService, private excelService: ExcelService) {
	}

	ngOnInit() {
		this.retriveCostEstimator();

		this.columns = [
			{field: 'psa', header: 'PSA#'},
			{field: 'program', header: 'Lead Program'},
			{field: 'commodity', header: 'Material Group'},
			{field: 'commodityName', header: 'Material Group Description'},
			{field: 'costEstimator', header: 'CostEstimator'},
			{field: 'buddyCostEstimator', header: 'Updated CostEstimator'},
			{field: 'fromDate', header: 'From Date'},
			{field: 'toDate', header: 'To Date'},
			{field: 'updateBy', header: 'Updated By'},
			{field: 'updateDate', header: 'Updated Date'},
			{field: 'status', header: 'Reason'}
		];
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month - 1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);
		this.breadCrumItems = [
			{label: 'Buyer Home Page', url: '#/buyer-supplier/buyer', target: '_self'},
			{label: 'Update Cost Estimator', url: '#/cost-estimator', target: '_self'}
		];

	}

	onFilter(event: any, table: any): void {
		if (!!event.filteredValue) {
			this.costEstimatorExcel = event.filteredValue;
		}
	}

	onRowSelect(data) {
		this.router.navigate(['create-cost-estimator/edit/' + data.id]);
	}

	addcostEstimator() {
		this.router.navigate(['create-cost-estimator/create/' + null]);
	}

	retriveCostEstimator() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		const isBuyer = apsRoles.includes('Buyer');
		if (isBuyer) {
			userId = sessionStorage.getItem('userId');
		}
		this.costEstimatorService.findAll(userId, isBuyer).subscribe(response => {
			this.costEstimators = response;
			this.costEstimatorExcel = this.costEstimators;
		});
	}

	nullCheck(date) {
		return (!date && (date === null || date === 'null'));
	}

	exportAsXLSX() {
		const costEstimatorExports = [];

		this.costEstimatorExcel.forEach(costEstimator => {
			const costEstimatorExport = {};
			costEstimatorExport['PSA#'] = costEstimator.psa;
			costEstimatorExport['Lead Program'] = costEstimator.program;
			costEstimatorExport['Material Group'] = costEstimator.commodity;
			costEstimatorExport['Material Group Description'] = costEstimator.commodityName;
			costEstimatorExport['CostEstimator'] = costEstimator.costEstimator;
			costEstimatorExport['Updated CostEstimators'] = costEstimator.buddyCostEstimator;
			costEstimatorExport['From Date'] =
				costEstimator.fromDate ? moment(costEstimator.fromDate).format('MM/DD/YYYY HH:MM') : costEstimator.fromDate;
			costEstimatorExport['To Date'] =
				costEstimator.toDate ? moment(costEstimator.toDate).format('MM/DD/YYYY HH:MM') : costEstimator.toDate;
			costEstimatorExport['Updated By'] = costEstimator.updateBy;
			costEstimatorExport['Updated Date'] =
				costEstimator.updateDate ? moment(costEstimator.updateDate).format('MM/DD/YYYY HH:MM') : costEstimator.updateDate;
			costEstimatorExport['Reason'] = costEstimator.status;

			costEstimatorExports.push(costEstimatorExport);
		});

		this.excelService.exportAsExcelFile(costEstimatorExports, 'costEstimators');
	}

	private onBulkUpdate() {

		if (!this.dtSelectedRows || this.dtSelectedRows.length === 0) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'Please select CostEstimator Contact from table you want to update.'
			});

			return;
		}

		if (this.isMoving === 'Moving') {
			this.toDate = null;
		}

		//this.buddyBuyerMap['isMoving'] = this.isMoving;
		this.costEstimatorMap['fromDate'] = this.fromDate;
		this.costEstimatorMap['toDate'] = this.toDate;
		this.costEstimatorMap['buddyCostEstimator'] = this.costEstimator;

		const ids = [];
		for (const dtSelectedRow of this.dtSelectedRows) {
			const id = {id: dtSelectedRow.id, psa: dtSelectedRow.psa, costEstimator: dtSelectedRow.costEstimator};
			ids.push(id);
		}
		if (!this.isMandatoryField()) {
			this.validationMessage = 'CDSID is required';
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}
		this.costEstimatorMap['ids'] = ids;
		if (!this.costEstimator) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to provide costEstimator Contact for Bulk update.'
			});
		} else if (this.isMoving === 'Vacation'
			&& (this.nullCheck(this.costEstimatorMap['toDate']))) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set both From and To date for costEstimator Contact.'
			});
		} else if (this.isMoving === 'Moving'
			&& (this.nullCheck(this.costEstimatorMap['fromDate']))) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set From date for costEstimator Contact.'
			});
		} else {
			this.costEstimatorMap['buddyCostEstimator'] = this.costEstimator;
			this.costEstimatorMap['updatedBy'] = sessionStorage.getItem('userId');
			this.costEstimatorService.validateCostEstmator(this.costEstimatorMap).subscribe(responseUpdate =>
			{
				if (responseUpdate.status === 'Failure') {
					this.costEstimatorService.bulkUpdateCostEstimators(this.costEstimatorMap).subscribe(response => {
						this.dtSelectedRows = [];
						if (response) {
							this.retriveCostEstimator();
							this.messageService.add({
								severity: 'success',
								summary: 'Success',
								detail: 'costEstimators Contact information saved successfully for all selected costEstimators. Please allow at least 15 minutes for the changes to take effect.',
							});
						}
					});
				}
			});
		}
	}
	isMandatoryField() {
		if (this.isMoving === 'Moving') {
			const mandatory = this.fromDate && this.costEstimator;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.isMoving === 'Vacation') {
			const mandatory = this.toDate && this.fromDate && this.costEstimator;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}
	private validateCostEstimator(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.costEstimatorMap['buddyCostEstimator'] = this.costEstimator;
			this.costEstimatorMap['updatedBy'] = sessionStorage.getItem('userId');
			this.costEstimatorService.validateCostEstmator(this.costEstimatorMap).subscribe(response => {
				if (response.status === 'Success') {
					this.isBuyer = true;
					this.validationMessage = response.validationMessage;
					resolve(true);
				} else {
					this.isBuyer = false;
					resolve(false);
				}
			});
		});
	}
	restrictSpecialCharacters(event: KeyboardEvent) {
		const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
		if (!allowedKeys.includes(event.key) && !/[a-zA-Z0-9\s]/.test(event.key)) {
			event.preventDefault();
		}
	}
}
