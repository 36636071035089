/**
 * @author DCHIRUM1
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimpleResponse } from 'src/app/models/SimpleResponse';
import { environment } from '../../../environments/environment';
import { Config } from '../../config';

@Injectable({
	providedIn: 'root'
})
export class CostEstimatorService {

	private config = new Config();

	constructor(private http: HttpClient) {
	}

	findAll(userId, isBuyer): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/costEstimator' + '/all/' + userId + '/' + isBuyer);
	}

	findCostEstimator(costEstimatorId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/costEstimator/' + costEstimatorId);
	}

	saveCostEstimator(costEstimator): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/costEstimator/', costEstimator);
	}

	bulkUpdateCostEstimators(costEstimatorMap): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/costEstimator/bulkUpdate', costEstimatorMap);
	}

	validateCostEstmator(costEstimatorMap): Observable<SimpleResponse> {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL +
			'edcmservice/costEstimator/validateCostEstimator', costEstimatorMap);
	}
}
