import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {RfiComm2PartbaseRequestService} from 'src/app/services/rfi/rfi-comm2-partbase-request.service';
import {RfiThresholdRequestService} from 'src/app/services/rfi/rfi-threshold-request.service';

@Component({
	selector: 'create-comm2-partbase',
	templateUrl: './create-comm2-partbase.component.html'
})
export class CreateComm2PartbaseComponent implements OnInit {

	private partPref;
	private partBase;
	private partSuff;
	private mode;
	private isEdit;
	private comm2PartBase: any;
	private commodities;
	private programs;
	private breadCrumItems: MenuItem[];

	constructor(private activatedRoute: ActivatedRoute, private rfiThresholdRequestService: RfiThresholdRequestService,
				private comm2PartService: RfiComm2PartbaseRequestService,
				private messageService: MessageService, private confirmationService: ConfirmationService, private fb: FormBuilder) {

	}

	ngOnInit(): void {
		const that = this;
		// tslint:disable-next-line:no-unused-expression
		this.comm2PartBase = {};
		that.retrieveRouteParameter();
	}

	onSave() {

		// let rfiThresholdObj:any = {};
		// rfiThresholdObj['rfiIdentity'] = {};
		// rfiThresholdObj['rfiIdentity']['program'] = this.comm2PartBase.programObj.value;
		// rfiThresholdObj['rfiIdentity']['commodity'] = this.comm2PartBase.commodityObj.name;
		// rfiThresholdObj['mcost'] = this.comm2PartBase.mcost;
		// rfiThresholdObj['tcost'] = this.comm2PartBase.tcost;

		this.comm2PartBase.commodity = this.comm2PartBase.commodityObj.name;

		this.comm2PartService.saveComm2Partbase(this.comm2PartBase).subscribe(response => {
			if (response) {
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Commodity for the Part Updated successfully.'
				});
			} else {
				this.messageService.add({
					severity: 'warn',
					summary: 'Warning',
					detail: 'Comm2 PartBase given part number commodity already available, please different combination.'
				});
			}
		});
	}

	fetchCommoditiesdData() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.rfiThresholdRequestService.getProgramsCommodities(userId).subscribe(response => {
			this.commodities = response.commodities.filter(commodity => commodity.name.trim() !== 'EDCM000');
			this.comm2PartService.findComm2Partbase(this.partPref, this.partBase, this.partSuff, userId).subscribe(resObj => {
				this.comm2PartBase = resObj;
				this.comm2PartBase.commodityObj = {name: resObj.commodity, desc: resObj.commodityDesc};
			});
		});
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.partPref = params.get('partPref');
			this.partBase = params.get('partBase');
			this.partSuff = params.get('partSuff');
			this.mode = params.get('mode');
			this.isEdit = this.mode === 'edit';

			this.fetchCommoditiesdData();
			const addEdit = this.isEdit ? 'Edit Commodity' : 'Add Commodity';

			this.breadCrumItems = [
				{label: 'Buyer', url: '#/buyer-supplier/buyer'},
				{label: 'Manage commodities', url: '#/comm2-partbase'},
				{label: addEdit}
			];
		});
	}


}
