/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';

/** This component handles redirecting users to the auth provider */
@Component({
	template: `
		<h3>Redirecting to login server</h3>
	`
})
export class LoginComponent implements OnInit {

	private ADFS_URL = environment.ADFS_URL;
	private ADFS_CLIENT_ID = environment.ADFS_CLIENT_ID;
	private ADFS_RESOURCE = environment.ADFS_RESOURCE;

	endPoint = this.ADFS_URL
		+ '?client_id=' + this.ADFS_CLIENT_ID
		+ '&resource=' + this.ADFS_RESOURCE
		+ '&response_type=token+id_token'
		+ '&redirect_uri=' + encodeURIComponent(window.location.origin + '/');

	/** the configured URL endpoint to send the user's browser to for token negotiation */

	/*	_endPoint = 'https://corpqa.sts.ford.com/adfs/oauth2/authorize' +
			'?client_id=urn:ecar_preprod:clientid:web_ecarpreprodwebsite:dev' +
			'&resource=urn:ecar_preprod:resource:web_ecarpreprodwebsite:dev' +
			'&response_type=token' +
			'&redirect_uri=' + encodeURIComponent(window.location.origin + '/');*/

	/** once the component is up and running, redirect the user to the auth provider */
	ngOnInit() {
		console.log('LoginComponent : trying to get token....');
		console.log(this.endPoint);
		//can't use the Angular location service here, as it always constructs against the app base href
		window.location.replace(this.endPoint);

	}
}
