/**
 * @author DCHIRUM1
 */
import {
	Component,
	OnInit
} from '@angular/core';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import moment from 'moment';
import {
	LazyLoadEvent,
	MenuItem,
	MessageService
} from 'primeng/api';
import { ExcelService } from '../services/excel.service';
import { SupplierEngineerService } from '../services/rfi/supplierEngineer.service';

@Component({
	selector: 'app-supplier-engineer',
	templateUrl: './supplier-engineer.component.html',
	styleUrls: ['./supplier-engineer.component.css'],
	providers: [MessageService]
})
export class SupplierEngineerComponent implements OnInit {
	private columns = [];
	private supplierEngineers = [];
	private supplierEngineerExcel = [];
	private selectedBuddyBuyer;
	private breadCrumItems: MenuItem[];
	private dtSelectedRows = [];
	private isMoving = 'Moving';
	private supplier;
	private fromDate;
	private toDate;
	private suppContactMap = {};
	private isSubmit;
	loading = true;
	filterOptions: {};
	totalRecords: number;

	minDate: Date | undefined;

	constructor(private activatedRoute: ActivatedRoute, private router: Router,
				private supplierEngineersService: SupplierEngineerService,
				private messageService: MessageService, private excelService: ExcelService) {
	}

	ngOnInit() {
		this.filterOptions = {};
		this.retriveSupplierEngineer(this.filterOptions);

		this.columns = [
			{field: 'psa', header: 'PSA#'},
			{field: 'program', header: 'Lead Program'},
			{field: 'commodity', header: 'Material Group'},
			{field: 'commodityName', header: 'Material Group Description'},
			{field: 'supplier', header: 'Secondary Supplier'},
			{field: 'supplierEngineer', header: 'Update Secondary Supplier'},
			{field: 'fromDate', header: 'From Date'},
			{field: 'toDate', header: 'To Date'},
			{field: 'updateBy', header: 'Updated By'},
			{field: 'updateDate', header: 'Updated Date'},
			{field: 'status', header: 'Reason'}
		];
		const today = new Date();
		const month = today.getMonth() +1;
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month -1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));

		this.breadCrumItems = [
			{label:  this.isBuyerRole() || this.isAdminRole(apsRoles) ?
				'Buyer' : 'Supplier', url: '#/buyer-supplier/supplier', target: '_self'},
			{label: 'Update Secondary Supplier'}
		];
	}

	onFilter(event: any, table: any): void {
		if (!!event.filteredValue) {
			this.supplierEngineerExcel = event.filteredValue;
		}
	}

	selectAll(event: any){
		if (event.checked){
			this.dtSelectedRows = this.supplierEngineers;
		} else {
			this.dtSelectedRows = [];
		}

	}

	onRowSelect(data) {
		this.router.navigate(['create-supplier-engineer/edit/' + data.id]);
	}

	addSupplierEngineer() {
		this.router.navigate(['create-supplier-engineer/create/' + null]);
	}

	isAdminRole(apsRoles) {
		return apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('PD_Engineer') || apsRoles.includes('eDCM_Super_Buyer');
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	retriveSupplierEngineer(filterOptions, event?: LazyLoadEvent) {
		this.loading = true;
		let page = 0;
		let size = 10;
		let sortColumn = '';
		let sortOrder = 0;

		if (event) {
			page = event.first / event.rows;
			size = event.rows;
			sortColumn = event.sortField;
			sortOrder = event.sortOrder;
		}

		if (event && event.filters) {
			filterOptions = {
				psa: event.filters.psa ? event.filters.psa.value : null,
				program: event.filters.program ? event.filters.program.value : null,
				commodity: event.filters.commodity ? event.filters.commodity.value : null,
				commodityName: event.filters.commodityName ? event.filters.commodityName.value : null,
				supplier: event.filters.supplier ? event.filters.supplier.value : null,
				supplierEngineer: event.filters.supplierEngineer ? event.filters.supplierEngineer.value : null,
				fromDate: event.filters.fromDate ? event.filters.fromDate.value : null,
				toDate: event.filters.toDate ? event.filters.toDate.value : null,
				updateBy: event.filters.updateBy ? event.filters.updateBy.value : null,
				updateDate: event.filters.updateDate ? event.filters.updateDate.value : null,
				status: event.filters.status ? event.filters.status.value : null
			};
		}

		let userId = sessionStorage.getItem('emailAddress');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (this.isAdminRole(apsRoles)) {
			userId = 'eDCM_IT_ADMIN';
		}

		const isBuyer = this.isBuyerRole();
		if (isBuyer) {
			userId = sessionStorage.getItem('userId');
		}
		this.supplierEngineersService.findAll(userId, isBuyer, page, size, sortColumn, sortOrder, filterOptions).subscribe(response => {
			this.loading = false;
			this.totalRecords = response.totalRecordsAll;
			this.supplierEngineers = response.all;
			this.supplierEngineerExcel = this.supplierEngineers;
		});
	}

	nullCheck(date) {
		return (!date || (date === null || date === 'null'));
	}

	isValidDates(fromDate, toDate) {
		return moment(fromDate) < moment(toDate);
	}

	exportAsXLSX() {
		const supplierEngineerExports = [];

		this.supplierEngineerExcel.forEach(supplierEngineer => {
			const supplierEngineerExport = {};
			supplierEngineerExport['PSA#'] = supplierEngineer.psa;
			supplierEngineerExport['Lead Program'] = supplierEngineer.program;
			supplierEngineerExport['Material Group'] = supplierEngineer.commodity;
			supplierEngineerExport['Material Group Description'] = supplierEngineer.commodityName;
			supplierEngineerExport['Supplier'] = supplierEngineer.supplier;
			supplierEngineerExport['Updated Supplier'] = supplierEngineer.supplierEngineer;
			supplierEngineerExport['From Date'] =
				supplierEngineer.fromDate ? moment(supplierEngineer.fromDate).format('MM/DD/YYYY HH:MM') : supplierEngineer.fromDate;
			supplierEngineerExport['To Date'] =
				supplierEngineer.toDate ? moment(supplierEngineer.toDate).format('MM/DD/YYYY HH:MM') : supplierEngineer.toDate;
			supplierEngineerExport['Updated By'] = supplierEngineer.updateBy;
			supplierEngineerExport['Updated Date'] =
				supplierEngineer.updateDate ? moment(supplierEngineer.updateDate).format('MM/DD/YYYY HH:MM') : supplierEngineer.updateDate;
			supplierEngineerExport['Reason'] = supplierEngineer.status;

			supplierEngineerExports.push(supplierEngineerExport);
		});

		this.excelService.exportAsExcelFile(supplierEngineerExports, 'supplierEngineers');
	}

	isMandatoryField() {
		if (this.isMoving === 'Moving') {
			const mandatory = this.fromDate && this.supplier;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.isMoving === 'Vacation') {
			const mandatory = this.toDate && this.fromDate && this.supplier;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}


	onRowSelection(event) {
		this.dtSelectedRows.push(event.data);
	}

	onRowUnSelection(event) {
		this.dtSelectedRows = this.dtSelectedRows.filter(item => item !== event.data);
	}

	private onBulkUpdate() {

		if (!this.dtSelectedRows || this.dtSelectedRows.length === 0) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please select PSA(s) from table below to update.'
				});
			}, 30);
			this.dtSelectedRows = [];
			return;
		}

		if (!this.isMandatoryField()) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}
		const regexp = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$'); // NOSONAR

		if (!regexp.test(this.supplier)) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please enter valid supplier engineer email address.'
				});
			}, 30);

			return;
		}

		if (this.isMoving === 'Moving') {
			this.toDate = null;
		}

		//this.buddyBuyerMap['isMoving'] = this.isMoving;
		this.suppContactMap['fromDate'] = this.fromDate;
		this.suppContactMap['toDate'] = this.toDate;
		this.suppContactMap['suppSubContact'] = this.supplier;
		this.suppContactMap['updatedBy'] = sessionStorage.getItem('userId');
		const ids = [];
		for (const dtSelectedRow of this.dtSelectedRows) {
			const id = {id: dtSelectedRow.id, psa: dtSelectedRow.psa, supplier: dtSelectedRow.supplier};
			ids.push(id);
		}

		this.suppContactMap['ids'] = ids;

		if (!this.supplier) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'You need to provide Supplier Engineer for Bulk update.'
				});
			}, 30);
		} else if (this.isMoving === 'Vacation'
			&& (this.nullCheck(this.suppContactMap['toDate']))) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else if (this.isMoving === 'Moving'
			&& (this.nullCheck(this.suppContactMap['fromDate']))) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else {
			if (this.isMoving === 'Vacation' && !this.isValidDates(this.suppContactMap['fromDate'], this.suppContactMap['toDate'])) {
				setTimeout(() => {
					this.messageService.add({
						life: 300000, severity: 'warn',
						summary: 'From date must be smaller than To date for Supplier Engineer.'
					});
				}, 30);

				return;
			}

			this.supplierEngineersService.bulkUpdateSupSubContacts(this.suppContactMap).subscribe(response => {
				if (response) {
					this.retriveSupplierEngineer(this.filterOptions);
					this.dtSelectedRows = [];
					setTimeout(() => {
						this.messageService.add({
							life: 300000, severity: 'success',
							summary: 'Supplier Engineer information ' +
								'saved successfully for all selected PSAs. ' +
								'Please allow at least 15 minutes for the changes to take effect.'
						});
					}, 30);
				}
			});
		}
	}


}
