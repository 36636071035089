/**
 * @author DCHIRUM1
 */
import {Component, HostListener, OnInit, VERSION} from '@angular/core';
import {environment} from '../environments/environment';
import {UserIdService} from './services/user-id.service.service';
import {ApsService} from './services/aps.service';
import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

declare let dT;
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle


/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	/** Boolean for whether the menu is changed to collapsed 'hamburger menu' or full top horizontal menu */
	hamMenu: boolean;
	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;
	/** A listener to toggle between full screen menu and collapsed hamburger menu for smaller screens */
	userName;

	constructor(private userIdService: UserIdService, private router: Router,private roleService: ApsService, private http: HttpClient) {
		if (typeof dT != 'undefined' && dT.initAngularNg) {
			dT.initAngularNg(http, HttpHeaders);
		}
	}

	ngOnInit() {
		// this.roleService.getApsUserRoles().subscribe(response => {
		// 	this.roleService.roles.next(response);
		// });

		this.userIdService.userNameSubject.subscribe(value => {
			this.userName = value;
		});
	}

	@HostListener('window:resize', ['$event']) onResize(event?) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}
}
