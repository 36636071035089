import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import moment from 'moment';
import {SupplierContactService} from '../../services/rfi/supplierContact.service';

@Component({
	selector: 'create-supplier-contact',
	templateUrl: './create-supplier-contact.component.html',
	styleUrls: ['./create-supplier-contact.component.css'],
	providers: [MessageService]
})
export class CreateSupplierContactComponent implements OnInit {

	minDate: Date | undefined;
	private supplierContactId;
	private mode;
	private isEdit;
	private supplierContact: any = {};
	private breadCrumItems: MenuItem[];
	private isFromDateChange = false;
	private isToDateChange = false;
	private orgFromDate;
	private orgToDate;
	private buyerList = [];
	private selectedSupplierContact;
	private isSubmit;

	constructor(private activatedRoute: ActivatedRoute, private supplierContactService: SupplierContactService,
				private messageService: MessageService, private confirmationService: ConfirmationService,
				private fb: FormBuilder) {

	}

	ngOnInit(): void {
		const that = this;
		// tslint:disable-next-line:no-unused-expression
		this.supplierContact.isMoving = 'Moving';
		that.retrieveRouteParameter();
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month - 1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));

		const buyerSupplierUrl = this.isBuyerRole() || this.isAdminRole(apsRoles) === 'buyer' ? 'buyer' : 'supplier';


		this.breadCrumItems = [
			{label: this.isBuyerRole() || this.isAdminRole(apsRoles) ?
				'Buyer Home Page' : 'Supplier Home Page', url:
					'#/buyer-supplier/'+buyerSupplierUrl, target: '_self'},
			{label: 'Edit Supplier'}
		];
	}

	isAdminRole(apsRoles) {
		return apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('PD_Engineer') || apsRoles.includes('eDCM_Super_Buyer');
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	isValidDates(fromDate, toDate) {
		return moment(fromDate) < moment(toDate);
	}

	isMandatoryField() {
		if (this.supplierContact.isMoving === 'Moving') {
			const mandatory = this.supplierContact.fromDate && this.supplierContact.supplierContact;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.supplierContact.isMoving === 'Vacation') {
			const mandatory = this.supplierContact.toDate && this.supplierContact.fromDate && this.supplierContact.supplierContact;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}

	private onFromDateChange() {
		this.isFromDateChange = true;
	}

	private onToDateChange() {
		this.isToDateChange = true;
	}

	private onSave() {
		const regexp = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$');// NOSONAR

		if (!this.isMandatoryField()) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required.'
				});

			}, 30000);
			return;
		}
		if (!regexp.test(this.supplierContact.supplierContact)) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please enter valid supplier email address.'
				});
			}, 30);
			return;
		}

		if (!this.isFromDateChange && this.orgFromDate && this.supplierContact.isMoving === 'Vacation') {
			this.supplierContact.fromDate = this.orgFromDate;
		}

		if (!this.isFromDateChange && this.supplierContact.isMoving === 'Moving') {
			this.supplierContact.fromDate = null;
		}

		if (!this.isToDateChange) {
			this.supplierContact.toDate = null;
		}

		if (this.supplierContact.isMoving === 'Moving') {
			this.supplierContact.toDate = null;
		}

		if (this.supplierContact.isMoving === 'Vacation' && !this.supplierContact.toDate) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding. '
				});
			}, 30);
		} else if (this.supplierContact.isMoving === 'Moving' && !this.supplierContact.fromDate) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding. '
				});
			}, 30);
		} else {
			if (this.supplierContact.isMoving === 'Vacation'
				&& !this.isValidDates(this.supplierContact.fromDate, this.supplierContact.toDate)) {
				setTimeout(() => {
					this.messageService.add({
						life: 300000, severity: 'warn',

						summary: 'From date must be smaller than To date for Supplier Contact.'
					});
				}, 30);

				return;
			}

			this.supplierContact.createBy = sessionStorage.getItem('userId');
			this.supplierContact.updateBy = sessionStorage.getItem('userId');
			this.supplierContactService.saveSupplierContact(this.supplierContact).subscribe(response => {
				if (response) {
					setTimeout(() => {
						this.messageService.add({
							life: 300000, severity: 'success',
							summary: 'Supplier ID saved successfully. Please allow at least 15 minutes for the changes to take effect.'
						});
					}, 30);

					this.supplierContact.fromDate = moment(this.supplierContact.fromDate).format('MM/DD/YYYY');
					if (this.supplierContact.toDate) {
						this.supplierContact.toDate = moment(this.supplierContact.toDate).format('MM/DD/YYYY');
						this.supplierContact.isMoving = 'Vacation';
					} else {
						this.supplierContact.isMoving = 'Moving';
					}
					this.selectedSupplierContact = this.supplierContact.supplierContact;
				}
			});
		}
	}

	private fetchSupplierContact() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.supplierContactService.findSupplierContact(this.supplierContactId).subscribe(resObj => {
			this.supplierContact = resObj;
			this.supplierContact.isMoving = 'Moving';
			this.orgFromDate = this.supplierContact.fromDate;

			if (this.supplierContact.fromDate) {
				this.supplierContact.fromDate = moment(this.supplierContact.fromDate).format('MM/DD/YYYY');
			}

			if (this.supplierContact.toDate) {
				this.supplierContact.isMoving = 'Vacation';
				this.orgToDate = this.supplierContact.toDate;
				this.supplierContact.toDate = moment(this.supplierContact.toDate).format('MM/DD/YYYY');
			}
		});
	}

	private onBuyerChange() {
		this.supplierContact.supplierContact = this.selectedSupplierContact;
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.supplierContactId = params.get('supplierContactId');
			this.mode = params.get('mode');
			this.isEdit = this.mode === 'edit';

			const addEdit = this.isEdit ? 'Edit Update Supplier' : 'Add Update Supplier Contact';

			if (this.isEdit) {
				this.fetchSupplierContact();
			}

		});
	}
}
