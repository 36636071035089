/**
 * @author DCHIRUM1
 */
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

/** This class is an implementation of an HttpInterceptor
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	/** This method is required by the fact that this class implements HttpInterceptor.
	 * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	 *
	 * @param request the HttpRequest object
	 * @param next the Http handling object
	 * @returns the Observable representing the next HttpEvent
	 */

	constructor(private router: Router) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = sessionStorage.getItem('encodedAccessToken');
		const authTokenOnly = {
			Authorization: 'Bearer ' + token
		};
		const authTokenAndContentType = {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		};

		function isUploadTemplateEndpoint() {
			if (
				request.url.indexOf('/edcmservice/help-document/upload') !== -1 || request.url.indexOf('/edcmservice/rfi-document/uploadFile/') !== -1
			) {
				return true;
			} else {
				return false;
			}
		}

		function buildHeaders(uploadFileHeader, defaultHeader) {
			return isUploadTemplateEndpoint() ? uploadFileHeader : defaultHeader;
		}

		function getHeaders() {
			return buildHeaders(authTokenOnly, authTokenAndContentType);
		}

		request = request.clone({
			headers: new HttpHeaders(getHeaders())
		});
		return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				console.log('hide spinner');
			}
		}, (error1) => {
			console.log('error: hide spinner');
			if (error1.status === 401) {
				this.router.navigate(['/permission-denied']);
			}
		}
		));

	}
}
