/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {SimpleResponse} from '../../models/SimpleResponse';


@Injectable({
	providedIn: 'root'
})
export class ManualRfiRequestService {

	private config = new Config();

	// tslint:disable-next-line:indent
	constructor(private http: HttpClient) {
	}

	findAll(supplier, userId, role, page, size, sortColumn, sortOrder, filetrOptions): Observable<any> {
		if (!sortColumn) {
			sortColumn = 'No column';
		}
		return this.http
			.post(environment.BACKEND_URL +
				'edcmservice/manual-rfiHeader/list/' + supplier + '/' + userId + '/' + role + '/' + page + '/' +
				size + '/' + sortColumn + '/' + sortOrder, filetrOptions);
	}

	findAllCMFDCR(supplier, userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/cmfDCRlist/' + supplier + '/' + userId);
	}


	findAllRfiData(supplier, userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/listRfiData/' + supplier + '/' + userId);
	}

	findRfiListForPsa(psa): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/rfiList/' + psa);
	}

	programCount(supplier, userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/programCount/' + supplier + '/' + userId);
	}

	findById(id: string): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/' + id);
	}

	updateStatusById(rfiPrefix, rfiNum, status): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/updateStatus/' + rfiPrefix + '/' + rfiNum + '/' + status, null);
	}

	updateExtReqCount(rfiPrefix, rfiNum, suuplier): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL +
				'edcmservice/manual-rfiHeader/updateExtReqCount/' + rfiPrefix + '/' + rfiNum + '/' + suuplier, null);

	}

	updateRFI(rfiPrefix, rfiNum, rfi, isSupplier): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/updateRFI/' + rfiPrefix + '/' + rfiNum + '/' + isSupplier, rfi);
	}

	createNewRFI(): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/createNew', null);
	}

	saveRFI(rfiData): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/saveRFI', rfiData);
	}

	saveRFIInternalCostDetails(rfiData): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/saveRFIInternalCostDetails', rfiData);
	}

	deleteRFIInternalCostDetails(rfiData): Observable<any> {
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/deleteRFIInternalCostDetails', rfiData);
	}

	validateBuyer(buddyBuyerMap): Observable<SimpleResponse> {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/validateBuyer', buddyBuyerMap);
	}

	validateEngineer(buddyBuyerMap: {}) {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/validateEngineer', buddyBuyerMap);
	}

	validateCostEstimator(buddyBuyerMap: {}) {
		return this.http.post<SimpleResponse>(environment.BACKEND_URL + 'edcmservice/manual-rfiHeader/validateCostEstimator', buddyBuyerMap);
	}
}
