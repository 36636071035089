/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RfiToolingRequestService {

	private config = new Config();

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiTooling');
	}

	findByRfiHeaderId(rfiPrefix, rfiNum): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rfiTooling/' + rfiPrefix + '/' + rfiNum);
	}

	saveToolings(toolings, rfiPrefix, rfiNum): Observable<any> {
		const rfiToolings = [];
		toolings.forEach(rfiQuestion => {
			rfiQuestion.cost = parseInt(rfiQuestion.cost, 10);
			rfiToolings.push(rfiQuestion);
		});
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/rfiTooling/' + rfiPrefix + '/' + rfiNum, rfiToolings);
	}

}
