/**
 * @author DCHIRUM1
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Config } from '../../config';

@Injectable({
	providedIn: 'root'
})
export class SupplierEngineerService {

	private config = new Config();

	constructor(private http: HttpClient) {
	}

	findAll(userId, isBuyer, page, size, sortColumn, sortOrder, filetrOptions): Observable<any> {
		if (!sortColumn) {
			sortColumn = 'updateDate';
		}
		return this.http
			.post(environment.BACKEND_URL + 'edcmservice/supplierEngineer' + '/all/' + userId + '/' +
				isBuyer + '/' + page + '/' + size + '/' + sortColumn + '/' + sortOrder, filetrOptions);
	}

	findSupplierEngineer(suppliercontactId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/supplierEngineer/' + suppliercontactId);
	}

	saveSupplierEngineer(suppliercontact): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/supplierEngineer/', suppliercontact);
	}

	bulkUpdateSupSubContacts(buddyBuyerMap): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/supplierEngineer/bulkUpdate', buddyBuyerMap);
	}
}
