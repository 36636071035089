/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RfiComm2PartbaseRequestService {

	private config = new Config();

	constructor(private http: HttpClient) {
	}

	findAll(userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rficomm2base' + '/' + userId);
	}

	findComm2Partbase(partPrefix, partBase, partSuffix, userId): Observable<any> {
		return this.http
			.get(environment.BACKEND_URL + 'edcmservice/rficomm2base/' + partPrefix + '/' + partBase + '/' + partSuffix + '/' + userId);
	}

	saveComm2Partbase(comm2Partbase): Observable<any> {
		return this.http.post(environment.BACKEND_URL + 'edcmservice/rficomm2base/', comm2Partbase);
	}
}
